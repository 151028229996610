@use '@/assets/styles' as *;

.calculator-radio {
    position: relative;
    z-index: 1;

    input {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 1;
        @include cursor-pointer;

        &:checked {
            ~ .calculator-radio__content {
                background-color: color-secondary(300);
                border-color: transparent;

                .value {
                    color: color-constants-white(100);
                }
            }
        }

        &:hover {
            ~ .calculator-radio__content {
                transform: translateY(-2px);
                opacity: .7;
            }
        }
    }


    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: rem(12) rem(16);
        border-radius: rem(12);
        border: 1px solid color-constants-white(8);
        transition: .3s border-color ease, background-color .3s ease, transform .3s ease, opacity .3s ease;

        .name {
            @include font-scraft-regular;
            color: color-constants-white(100);
            line-height: 116%;
            font-size: rem(15);
            white-space: nowrap;
        }

        .value {
            @include font-gcraft-semibold;
            color: rgba(255, 255, 255, .5);
            line-height: 132%;
            font-size: rem(13);
            margin-top: rem(2);
            transition: .3s color ease;
            white-space: nowrap;
        }

    }

}