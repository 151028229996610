@use "@/assets/styles/" as *;

.button-container {
  width: auto;
  height: auto;
  display: inline-block;
  transition: opacity .5s ease, visibility .5s ease;

  &.-disabled {
    pointer-events: none;
  }

  .button {
    transition: transform .3s ease, background-color .3s ease;
  }

  &:hover {
    >.button:not(:disabled) {
      transform: translateY(-3px);
    }
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: rem(12) rem(14);
  border: 1px solid white;
  border-radius: rem(38);
  @include font-scraft-regular;
  background: none;
  font-size: font(18);
  font-weight: 400;
  line-height: 100%;
  @include cursor-pointer;

  .icon,
  img {
    margin-right: rem(12);
    width: rem(24);
    height: rem(24);
  }
}

.button:disabled {
  @include cursor-default;
  opacity: 0.2;
}

.button-document,
.button-metamask {
  .button {
    color: color-constants-white(100);
    padding-right: rem(20);
  }
}

.button-metamask {
  .button {
    color: #000000;
    background-color: color-constants-white(100);
    padding-left: rem(24);
    padding-right: rem(24);
  }

  &:hover {
    >.button {
      background-color: color-constants-white(64);
    }
  }
}

.button-metamaskConnected {
  .button {
    text-wrap: nowrap;
    color: white;
    // background-color: color-constants-black(100);
    padding-left: rem(24);
    padding-right: rem(24);
  }

  .button-metamask-seqblock {
    padding: rem(4) rem(6);
    background-color: rgba(255, 255, 255, 0.08);
    border-radius: rem(33);
    margin-left: rem(4);
    font-size: rem(10);
    display: flex;
    align-items: flex-end;
    gap: rem(2);

    span {
      display: block;
      font-size: rem(5);

    }
  }

  &:hover {
    >.button {
      // background-color: color-constants-white(64);
    }
  }
}

.button-rewards {

  .button {

    border: 1px solid white !important;
    color: white;
    // background-color: color-constants-black(100);
    padding-left: rem(24);
    padding-right: rem(24);
    opacity: 1;
  }


}

.button-cross {
  opacity: 0.8;

  .button {
    color: color-constants-white(100);
    border-radius: 100%;
  }

  .icon {
    margin-right: 0;
  }

  &:hover {
    >.button {
      background-color: color-constants-white(64);
      transform: none !important;
    }
  }
}

.button-twitter {
  .button {
    color: color-background(50);
    background: color-constants-white(100);
    padding-right: rem(20);
  }

}

.button-mint {
  .button {
    color: color-constants-white(100);
    background-color: color-secondary(400);
    padding-right: rem(20);
    border-color: color-secondary(400);

    .icon {
      margin-right: rem(8);
    }
  }

}

.button-mint2 {
  .button {
    width: 100%;
    color: color-constants-white(100);
    background-color: color-secondary(400);
    padding-right: rem(20);
    border-color: color-secondary(400);

    .icon {
      margin-right: rem(8);
    }
  }

}

// .button-discord {
//   .button {
//     color: color-constants-white(100);
//     background-color: #5061F1;
//     padding-right: rem(20);
//     border-color: #5061F1;
//   }

// }

.button-discord{

  @include tablet {
    display: none !important;
  }
}

.button-login,
.button-discord {

  .button {
    text-wrap: nowrap;
    color: color-constants-white(100);

  }
}

.button-calculator {
  .button {
    color: color-constants-white(100);
    padding: rem(13) rem(24);
    align-items: flex-start;
    background: color-secondary(500);
    border: none;

    .icon {
      margin-right: rem(8);
    }
  }
}

.button-outline {
  .button {
    color: color-constants-white(100);
    padding: rem(13) rem(24);
  }
}


// 
.burger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include cursor-pointer;

  >* {
    width: rem(6);
    height: rem(6);
    border-radius: 100%;
    background-color: rgb(255, 255, 255);

    &:not(:first-child) {
      margin-left: rem(6);
    }
  }
}