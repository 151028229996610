@use 'sass:math';
@use '@/assets/styles' as *;

.switch {
    position: relative;

    // &__input{
    //     position: absolute;
    //     width: rem(72);
    //     height: rem(32);
    //     opacity: 0;
    //     left: 0;
    //     top: 0%;
    //     display: flex;
    //     z-index: 1;
    //     cursor: pointer;
    // }

    // &__body{
    //     border-radius: rem(42);
    //     width: rem(72);
    //     height: rem(32);
    //     cursor: pointer;
    //     background: rgba(0, 0, 0, 0.8);
    //     display: flex;
    //     -webkit-transition: .1s;
    //     transition: .1s;

    //     &::before{
    //         position: absolute;
    //         content: "";
    //         top: rem(4);
    //         left: rem(4);
    //         height: rem(24);
    //         width: rem(32);
    //         background-color: #ffadad;  
    //         // border: rem(1) solid white; // заменить цвета + сделать бордер радиус
    //         border-radius: rem(23);
    //         -webkit-transition: .4s;
    //         transition: transform .4s;
    //         z-index: 0;
    //     }
    // }

    &__text {

        position: absolute;
        // top: rem(5);
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-size: rem(10);
        // margin-top: rem(5);
        background-color: #1A1A23;
        color: #000000;
        @include font-scraft-bold;

        .text__on {
            margin-left: rem(12);
        }

        .text__off {
            margin-left: rem(14);
        }

        border-radius: rem(12);
        width: rem(32);
        height: rem(32);
        cursor: pointer;
        background: #ffadad;
        
    }
}

.activeFarming{
    background: #adffbe;
}
.-checked {

    .switch__body {
        &::before {
            transform: translateX(100%);
        }
    }
}

.-active {
    color: #1A1A23;
    transition: color 0.3s linear;
}

.-activeBlock {
    &::before {
        background-color: #adffbe;

    }
}





// 

// @use 'sass:math';
// @use '@/assets/styles' as *;

// .switch{
//     position: relative;

//     &__input{
//         position: absolute;
//         width: rem(172);
//         height: rem(32);
//         opacity: 0;
//         left: 0;
//         top: 0%;
//         display: flex;
//         z-index: 1;
//         cursor: pointer;
//     }

//     &__body{
//         border-radius: rem(42);
//         width: rem(142);
//         height: rem(32);
//         cursor: pointer;
//         background: rgba(0, 0, 0, 0.8);
//         display: flex;
//         -webkit-transition: .1s;
//         transition: .1s;

//         &::before{
//             position: absolute;
//             content: "";
//             top: rem(4);
//             left: rem(4);
//             height: rem(24);
//             width: rem(57);
//             background-color: #C4C4D4;  
//             // border: rem(1) solid white; // заменить цвета + сделать бордер радиус
//             border-radius: rem(23);
//             -webkit-transition: .4s;
//             transition: transform .4s;
//             z-index: 0;
//         }
//     }

//     &__text{
//         position: absolute;
//         width: rem(72);
//         top: rem(5);
//         display: flex;
//         text-transform: uppercase;
//         font-size: rem(10);
//         margin-top: rem(5);
//         color: #ACACC3;
//         @include font-scraft-bold;

//         .text__on{
//             margin-left: rem(11);
//             // padding-left: rem(1);
//         }

//         .text__off{
//             margin-left: rem(20);
//         }
//     }
// }

// .-checked{

//     .switch__body{
//         &::before {
//             transform: translateX(100%);
//             width: rem(67);
//         }
//     }
// }

// .-active{
//     color: #1A1A23;
//     transition: color 0.3s linear;
// }