@use '@/assets/styles' as *;

.game {
    position: relative;

    .slide {
        position: relative;
        width: 100%;
        height: 100%;
        padding-left: rem(48);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-height: 100vh;

        .content__wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;


            .wrapper__text {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                h3 {
                    color: color-constants-white(100);
                    font-size: font(43);
                    line-height: 96%;
                    letter-spacing: -0.04em;
                    font-weight: 400;
                }

                p {
                    color: color-constants-white(100);
                    font-size: rem(27);
                    line-height: 132%;
                    font-weight: 400;
                    max-width: rem(400);

                    @include mobile {
                        max-width: 100%;
                    }
                }

            }

            .text__row {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                margin-top: rem(16);

                @include mobile {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
        }

        .wrapper__image {
            @include containImage;
        }



        &_1 {
            @include mobile {
                padding-bottom: rem(400);
            }
            .wrapper__image {
                position: absolute;
                width: rem(1200);
                height: rem(1200);
                right: rem(-150);
                bottom: calc(50% - rem(1000));

                @include laptop-lg {
                    width: rem(1000);
                    height: rem(1000); 
                    bottom: calc(50% - rem(900));
                }

                @include tablet {
                    bottom: calc(50% - rem(900));
                    right: rem(-260);
                }

                @include mobile {
                    width: rem(1200);
                    height: rem(1200); 
                    right: rem(-370);
                    bottom: calc(50% - rem(1200));
                }

                > span {
                    position: absolute;
                    top: 0; left: 0;
                }
            }
        }

        &_2 {
            padding-bottom: rem(170);
            @include tablet {
                padding-bottom: rem(400);
            }
            @include mobile {
                padding-bottom: rem(500);
            }
            .wrapper__image {
                position: absolute;
                width: rem(1200);
                height: rem(1200);
                right: rem(-150);
                bottom: calc(50% - rem(1000));

                @include laptop-lg {
                    width: rem(1000);
                    height: rem(1000); 
                    bottom: calc(50% - rem(800));
                }

                @include tablet {
                    bottom: calc(50% - rem(800));
                }

                @include mobile {
                    width: rem(1000);
                    height: rem(1000); 
                    right: rem(-270);
                    bottom: calc(50% - rem(930));
                }

                > span {
                    position: absolute;
                    top: 0; left: 0;
                }
            }
        }

        &_3 {
            padding-bottom: rem(180);
            @include tablet {
                padding-bottom: rem(400);
            }
            @include mobile {
                padding-bottom: rem(300);
            }

            .wrapper__image {
                position: absolute;
                width: rem(1700);
                height: auto;
                bottom: calc(50% - rem(700)); left: -50%;

                @include laptop-lg {
                    width: rem(1300);
                    bottom: calc(50% - rem(550));
                    left: -30%;
                }

                @include tablet {
                    width: rem(900);
                    left: rem(40);
                    bottom: calc(50% - rem(400));
                }

                @include mobile {
                    width: rem(550);
                    left: 0;
                    bottom: calc(50% - rem(300));
                }


            }
        }

        &_4 {
            padding-bottom: rem(110);
            .row__image {
                position: relative;
                margin-left: rem(50);
                .video {
                    width: rem(396);
                    height: rem(236);
                }

                @include mobile {
                    margin-left: 0;
                    margin-top: rem(32);

                    .video {
                        width: rem(460);
                        height: rem(350);
                    }
                }
            }

            .wrapper__image {
                position: absolute;
                width: rem(1200);
                height: rem(1200);
                right: rem(-150);
                bottom: calc(50% - rem(1000));

                @include laptop-lg {
                    width: rem(1000);
                    height: rem(1000); 
                    bottom: calc(50% - rem(600));
                }

                @include tablet {
                    bottom: calc(50% - rem(600));
                    right: rem(-260);
                }

                @include mobile {
                    width: rem(400);
                    height: rem(400); 
                    right: rem(0);
                    bottom: calc(50% - rem(450));
                }

                > span {
                    position: absolute;
                    top: 0; left: 0;
                }
            }
        }
    }
}
