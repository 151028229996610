@use '@/assets/styles' as *;

.team {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh !important;
    padding: rem(100) 0;

    .border-bottom {
        transform: translateZ(1px);
    }

    &__content {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        @include tablet {
            flex-direction: column;
            justify-content: flex-start;
        }

        h2 {
            font-size: font(43);
            color: color-constants-white(100);
            line-height: 116%;
            margin-top: rem(60);
            @include tablet {
                display: none;
            }
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        row-gap: rem(44);
        column-gap: rem(30);
        max-width: rem(1200);
        // margin-left: rem(69);
        @include laptop-lg {
            row-gap: rem(32);
        }
        @include tablet {
            max-width: rem(900);
            row-gap: rem(24);
            margin-left: 0;
            margin-top: rem(24);
        }
        @include mobile {
            max-width: rem(500);
        }

        @for $i from 1 through 6 {
            .list__item:nth-child(#{$i}) {
                .item__avatar {
                    width: rem(170);
                    height: rem(170);
                }
            }
        }

        .list__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            flex: 1;

            .item__avatar {
                width: rem(140);
                height: rem(140);
                @include containImage;
                @include tablet {
                    width: rem(130) !important;
                    height: rem(130) !important;
                }
            }

            h5 {
                margin-top: rem(16);
                color: color-constants-white(100);
                font-size: font(24);
                line-height: 115%;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            p {
                margin-top: rem(6);
                color: #F1F1F1;
                font-size: font(12);
                line-height: 130%;
                font-weight: 400;
                text-align: center;
            }

            a {
                color: color-constants-white(100);
                @include cursor-pointer;
                transition: opacity .3s;
                &:hover {
                    text-decoration: underline;
                    opacity: .7;
                }
            }
        }
    }

    &__background {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        
        .background_1 {
            transition: opacity .5s ease;
            opacity: 0;
        }
    }
}

// animation
.team {
    .team__list > * {
        transition: .5s transform ease, .5s filter ease, .5s opacity;
        opacity: 0;
        transform: translateY(rem(100));
        // filter: blur(30px);
    }
}

.screen-scroll__slides {
    &.-active-id-team {
        .team {
            .background_1 {
                opacity: 1;
            }
            @for $i from 1 through 30 {
                .team__list > :nth-child(#{$i}) {
                    transition: 1s transform #{$i / 15}s ease, .75s filter #{$i / 15}s ease, 1s opacity #{$i / 15}s;
                }
            }
            .team__list > * {
                opacity: 1;
                transform: none;
                // filter: none;
            }
        }
    }
}