@use 'sass:math';
@use '@/assets/styles' as *;

.join-us {
    margin: 0 auto;
    display: flex;
    align-items: center;
    position: relative;
    max-width: rem(496);
    margin-bottom: rem(72);
    padding: rem(19) rem(24) rem(19) rem(120);
    border-radius: rem(32) rem(49) rem(49) rem(32);
    background: linear-gradient(90.68deg, rgba(73, 67, 158, 0.4) 1.86%, rgba(44, 40, 111, 0.4) 93.79%);


    @include tablet{
        max-width: rem(435);
        padding: rem(17) rem(24) rem(17) rem(83);
    }

    @include mobile{
        max-width: rem(372);
        padding: rem(14) rem(24) rem(14) rem(83);
    }

    &__image{
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(rem(-43), rem(-38.72));
        width: rem(170);
        height: rem(170);

        @include tablet{
            width: rem(155);
            height: rem(155);
            transform: translate(rem(-60.27), rem(-35.82));
        }

        @include mobile{
            width: rem(130);
            height: rem(130);
            transform: translate(rem(-40), rem(-30));
        }
    }

    p {
        font-size: rem(20);
        //font Craftwork Grotesk
        color: color-constants-white(100);
        margin-right: rem(16);

        @include tablet{
            font-size: rem(16);
        }

        @include mobile{
            font-size: rem(12);
        }
    }

    button {
        padding: rem(16.5) rem(30.5) rem(17.5) rem(30.5);
        border-radius: rem(38);
        border: rem(1) solid color-background(900);
        background-color: color-background(900);
        color: color-background(50);
        letter-spacing: 2%;
        font-size: rem(18);
        @include font-scraft-semibold;

        @include tablet{
            padding: rem(16) rem(27) rem(17) rem(27);
            font-size: rem(16);
        }

        @include mobile{
            padding: rem(14.5) rem(27) rem(15.5) rem(27);
            font-size: rem(12);
        }
    }
}