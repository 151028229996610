@use 'sass:math';
@use '@/assets/styles' as *;

.card{
    padding: rem(8) rem(8) rem(16) rem(8);
    border-radius: rem(16);
    border: rem(1) solid color-constants-white(8);
    position: relative;
    width: rem(246);

    @include tablet{
        width: rem(164);
        padding: rem(8) rem(8) rem(13) rem(8);
    }

    @include mobile{
        width: rem(240);
        padding: rem(8) rem(8) rem(9) rem(8);
    }

    &__tree-image{
        border-radius: rem(10);
        width: rem(230);
        height: rem(230);

        @include tablet{
            width: rem(148);
            height: rem(148);
        }

        @include mobile{
            width: rem(224);
            height: rem(224);
        }
    }

    &__rarity{
        position: absolute;
        top: rem(14);
        right: rem(14);
        padding: rem(4) rem(8) rem(6) rem(8);
        border-radius: rem(6);
        @include font-scraft-bold;
        font-size: rem(10);
        letter-spacing: 2%;
        color: color-constants-white(100);
    }

    &__footer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: rem(10);

        @include tablet{
            margin-top: rem(4);
        }

        @include mobile{
            margin-top: rem(6);
        }

        &__tag{
            font-size: rem(20);
            color: color-constants-white(64);
            @include font-scraft-regular;
            margin-left: rem(6);

            @include tablet{
                font-size: rem(14);
            }

            @include mobile{
                font-size: rem(20);
            }
        }

        &__card-number{
            display: flex;
            align-items: center;
            margin-right: rem(10);


            p{
                color: color-constants-white(100);
                font-size: rem(20);
                @include font-scraft-semibold;
                
                @include tablet{
                    font-size: rem(14);
                }

                @include mobile{
                    font-size: rem(20);
                }
            }

            // span{
            //     font-size: rem(8);
            //     color: color-constants-white(64);
            //     @include font-scraft-black;
            //     letter-spacing: 10%;
            //     width: rem(19);
            //     text-transform: uppercase;
            // }
            
            div{
                font-size: rem(8);
                margin-left: rem(5);
                color: color-constants-white(64);
                @include font-scraft-black;
                letter-spacing: 10%;
                width: rem(19);
                text-transform: uppercase;

                @include tablet{
                    font-size: rem(6);
                    width: rem(15);
                    margin-top: rem(2);
                }

                @include mobile{
                    width: rem(19);
                    font-size: rem(7);
                    margin-top: rem(1);
                }
            }
        }
    }


}

.-legendary{
    background: linear-gradient(180deg, rgba(120, 35, 35, 0.86) 0%, rgba(120, 35, 35, 0.25) 100%);

    .card__rarity{
        background-color: #782323;
    }
}

.-epic{
    background: linear-gradient(180deg, rgba(90, 31, 165, 0.87) 0%, rgba(90, 31, 165, 0.44) 100%);

    .card__rarity{
        background-color: #5A1FA5;
    }
}

.-rare{
    background: linear-gradient(180deg, rgba(25, 111, 123, 0.87) 0%, rgba(25, 111, 123, 0.28) 100%);

    .card__rarity{
        background-color: #196F7B;
    }
}

.-common{
    background: linear-gradient(180deg, rgba(73, 73, 102, 0.85) 0%, rgba(44, 44, 61, 0.79) 100%);

    .card__rarity{
        background-color: #6B7468;
    }
}
