@use '@/assets/styles' as *;

.stcheckbox {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &.-default {
        .stcheckbox__body {
            color: color-text(700);
        }
    }

    &.-active {
        .stcheckbox__body {
            color: color-secondary(500);
        }
    }

    &.-done {
        .stcheckbox__body {
            color: color-constants-white(24);
        }
    }


    .icon {
        width: rem(16);
        height: rem(16);
        min-width: rem(16);
        min-height: rem(16);

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__body {
        margin-left: rem(8);
        font-size: font(16);
        line-height: 135%;
    }
}