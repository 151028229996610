@use 'sass:math';
@use '@/assets/styles' as *;

.empty-card{
    padding: rem(8) rem(8) rem(16) rem(8);
    border-radius: rem(16);
    border: rem(1) solid color-background(200);
    width: rem(246);
    position: relative;

    @include tablet{
        width: rem(164);
        padding: rem(8) rem(8) rem(13) rem(8);
    }

    @include mobile{
        width: rem(240);
        padding: rem(8) rem(8) rem(9) rem(8);
    }

    &__image{
        width: rem(230);
        height: rem(230);
        border-radius: rem(10);
        border: rem(1) solid color-background(200);

        @include tablet{
            width: rem(148);
            height: rem(148);
        }

        @include mobile{
            width: rem(224);
            height: rem(224);
        }
    }

    &__rarity{
        position: absolute;
        top: rem(18);
        right: rem(18);
        border-radius: rem(6);
        border: rem(1) solid color-background(200);
        width: rem(46);
        height: rem(20);

        @include tablet{
            top: rem(16);
            right: rem(16);
        }


    }
    
    &__footer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: rem(12);

        @include tablet{
            margin-top: rem(8);
        }

        @include mobile{
            margin-top: rem(12);
        }

        &__first{
            border-radius: rem(6);
            border: rem(1) solid color-background(200);
            width: rem(74);
            height: rem(18);
            margin-left: rem(6);

            @include tablet{
                height: rem(15);
            }

            @include mobile{
                height: rem(18);
            }
        }

        &__second{
            border-radius: rem(6);
            border: rem(1) solid color-background(200);
            width: rem(50);
            height: rem(18);
            margin-right: rem(10);

            @include tablet{
                width: rem(38);
                height: rem(15);
                margin-right: rem(6);
            }

            @include mobile{
                width: rem(45);
                height: rem(18);
            }
        }
    }
}