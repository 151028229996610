@use 'sass:map';

$breakpoints: (
  xlg: 1920px,
  lg: 1440px,
  md: 992px,
  sm: 576px
);

$breakpoints-fonts: (
  xlg: 0.83333333333333vw, 
  lg: 1.11111111111111vw,
  md: 1.612903225806452vw,
  sm: 2.7777778vw
);

@function point($name) {
  @return map.get($breakpoints, $name);
}

@mixin generate-grid {
  @each $name, $size in $breakpoints-fonts {
    @media screen and (max-width: point($name)) {
      font-size: var(--breakpoint-font-#{$name});
    }
  }
}

@mixin laptop-xlg {
  @media screen and (max-width: point(xlg)) {
    @content;
  }
}

@mixin laptop-lg {
  @media screen and (max-width: point(lg)) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: point(md)) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: point(sm)) {
    @content;
  }
}

