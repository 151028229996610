@use '@/assets/styles' as *;

.about {
    position: relative;
    background: color-background(50);
    z-index: 2;
    overflow: visible !important;

    &__content {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        padding-left: rem(235) !important;
        display: flex;
        justify-content: center;
        align-items: center;

        @include tablet {
            padding-left: rem(32) !important;
        }

        @include mobile {
            padding-left: rem(16) !important;
        }
    }

    .content__wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: rem(100);

        @include tablet {
            flex-direction: column-reverse;
            margin-top: 0;
            padding-top: rem(400);
        }
        @include mobile {
            flex-direction: column-reverse;
            margin-top: 0;
            padding-top: rem(400);
        }

        .wrapper__text {
            max-width: rem(400);
            color: color-background(900);
            margin-top: rem(-100);

            @include tablet {
                margin-top: 0;
            }
            @include mobile {
                margin-top: rem(32);
            }


            h2 {
                font-size: font(53);
                line-height: 96%;
            }

            h3 {
                @include font-scraft-regular;
                font-size: font(28);
                line-height: 116%;
                margin-top: rem(32);
            }

            p {
                font-size: font(18);
                line-height: 124%;
                margin-top: rem(32);
            }

            span {
                color: color-constants-white(100);
            }

        }

        .wrapper__image {
            width: rem(675);
            height: rem(675);
            @include containImage;

            @include tablet {
                width: rem(500);
                height: rem(500);
                position: absolute;
                right: rem(-20);
                top: rem(-100);
            }

            @include mobile {
                width: rem(500);
                height: rem(500);
                right: rem(-40);
            }
        }
    }

    .content__background,
    .content__mascot {
        position: absolute;
    }

    .content__background {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -2;

        @include coverImage;
    }

    .content__mascot {
        width: rem(490);
        height: auto;
        @include containImage;
        left: 0;
        bottom: 0;
        transform: translate(-45%, 20%);

        @include mobile {
            display: none;
        }
    }
}

// animation
.about__content {
    transition: 0s transform .5s;


    @keyframes gradient {
        from {
            background-position: 0% 0%;
        }

        to {
            background-position: -200% 0%;
        }
    }
}

.-active-id-main {
    .about__content {
        transform: scale(0.5);
        transition: 1s transform ease;

        @include tablet {
            transform: scale(0.75);
        }
    }
}

.-active-id-about {
    .about__content {
        transform: none;
        transition: .5s transform ease;
    }
}

.-active-id-features {
    .about__content {
        transform: scale(0.5);
        transition: 1s transform ease;

        @include tablet {
            transform: scale(0.75);
        }
    }
}

$toAnimate: (
    '.about .wrapper__text > :nth-child(1)',
    '.about .wrapper__text > :nth-child(2)',
    '.about .wrapper__text > :nth-child(3)',
    '.about .wrapper__image',
);

.slide {
    $i: 0;

    @each $class in $toAnimate {

        #{$class} {
            transform: translate3d(0, rem(50), 0);
            transition: .75s transform;
            @include tablet {
                transform: translate3d(0, rem(20), 0);
            }
        }

        &.-active {
            #{$class} {
                transform: none;
                transition: 1s transform #{$i / 8}s ease;
            }
        }

        $i: $i + 1;
    }
}