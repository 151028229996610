@use '@/assets/styles' as *;

.accordion {
    position: relative;
    @include cursor-pointer;

    &:hover {
        h5 {
            opacity: .7;
            transform: translateY(-4px);
        }
    }

    &.-active {
        &:hover {
            h5 {
                opacity: 1;
                transform: translateY(-4px);
            }
            .accordion__body {
                transform: translateY(-4px);
            }
        }
        h5 {
            color: color-constants-white(100);
        }
        .accordion__body {
            max-height: rem(500);
            opacity: 1;
        }
    }

    h5 {
        color: color-primary(600);
        font-size: font(24);
        line-height: 116%;
        padding: rem(16) 0;
        transition: opacity .3s ease, transform .3s ease;
        display: inline-block;
    }



    &__body {
        position: relative;
        overflow: hidden;
        max-height: 0;
        transition: .5s max-height cubic-bezier(.81,.07,.15,.92), .5s opacity, .3s transform ease;
        opacity: 0;

        .desciption {
            // padding-top: rem(16);
            display: flex;
            align-items: center;

            &__image{
                display: none;
            }

            &__image-active{
                margin-right: rem(24);
            }

            p {
                color: color-constants-white(100);
                transition: .5s color;
                font-size: font(20);
                line-height: 116%;
                font-weight: 300;
                opacity: 0.8;
            }
        }
    }
}