@use '@/assets/styles' as *;

.modals {
    position: fixed;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;

    .modal-container {
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
    }

    &__background {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
        background-color: color-constants-black(80);
        z-index: 1;
        transition: opacity .5s ease;

        &:hover {
            opacity: .9;
            @include cursor-pointer;
        }
    }
}