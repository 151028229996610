@use 'sass:math';
@use '@/assets/styles' as *;

.statistics {
    position: relative;
    display: flex;
    align-items: center;
    padding: rem(12) rem(15) rem(12) rem(12);
    width: rem(320.5);
    z-index: 1;
    border: rem(1) solid transparent;
    // overflow: hidden;

    p{
        text-wrap: nowrap !important;
    }

    &::after {
        content: "";
        position: absolute;
        z-index: -2;
        left: rem(-1);
        top: rem(-1);
        width: calc(100% + rem(2));
        height: calc(100% + rem(3));
    }

    &::before {
        content: "";
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    @include tablet {
        width: rem(208.5);
        padding: rem(11) rem(15) rem(11) rem(12);
    }

    @include mobile {
        width: rem(243.5);
    }

    // border: linear-gradient(rgba(87, 219, 175) 100%) rem(1) solid; как сделать градиент бордера

    .pool-icon {
        width: rem(56);
        height: rem(56);
    }

    &__reward {
        display: flex;
        gap: rem(4);
    }

    &__information {
        margin-left: rem(16);

        @include tablet {
            margin-left: rem(8);
        }

        @include mobile {
            margin-left: rem(12);
        }

        .information__title {
            display: flex;
            @include font-scraft-semibold;
            color: color-constants-white(80);
            font-size: rem(15);
            margin-bottom: rem(6);

            @include tablet {
                font-size: rem(12);
            }

            @include mobile {
                font-size: rem(15);
            }
        }

        .information__amount {
            display: flex;
            align-items: center;
            @include font-scraft-semibold;
            font-size: rem(20);

            @include tablet {
                font-size: rem(14);
            }

            @include mobile {
                font-size: rem(18);
            }

            &__number {
                margin-right: rem(6);

                @include tablet {}
            }

            &__currency {
                opacity: 0.6;
                color: color-constants-white(100);
        
            }

            &__image {
                margin-left: rem(6);
                width: rem(22);
                height: rem(22);
                margin-top: rem(1);

                @include tablet {
                    margin-top: rem(0);
                    width: rem(18);
                    height: rem(18);
                }

                @include mobile {
                    margin-left: rem(6);
                    width: rem(22);
                    height: rem(22);
                    margin-top: rem(1);
                }
            }
        }

    }
}


.-seq-pool {
    margin-right: rem(6);
    flex-grow: 1;
    border-radius: rem(62) rem(6) rem(6) rem(62);
    border: rem(1) solid transparent;

    @include mobile {
        margin-bottom: rem(6);
    }


    .information__amount__number {
        color: color-primary(500);
    }

    .statistics__image {
        width: rem(56);
        height: rem(56);

        @include tablet {
            width: rem(30);
            height: rem(30);
        }

        @include mobile {
            width: rem(40);
            height: rem(40);
        }
    }

    &::after {
        content: "";
        border-radius: rem(62) rem(6) rem(6) rem(62);
        background: linear-gradient(96.18deg, rgba(119, 70, 216, 0.5) 5.24%, rgba(87, 219, 175, 0.5) 100%);
    }

    &::before {
        background: linear-gradient(96.18deg, #190D2E 5.24%, #16182B 100%);
        border-radius: rem(62) rem(6) rem(6) rem(62);
    }

}

.-farm-time {
    margin-right: rem(6);
    flex-grow: 1;
    border-radius: rem(6);

    @include mobile {
        margin-bottom: rem(6);
    }

    .information__amount__number {
        color: constants-white(100);
    }

    .statistics__image {
        width: rem(40);
        height: rem(54.4);

        @include tablet {
            width: rem(30);
            height: rem(42);
        }

        @include mobile {
            width: rem(35);
            height: rem(49);
        }
    }

    &::after {
        border-radius: rem(6);
        background: linear-gradient(100.27deg, rgba(43, 202, 149, 0.5) 17.53%, rgba(131, 87, 219, 0.5) 93.08%);
    }

    &::before {
        border-radius: rem(6);
        background: linear-gradient(100.27deg, #131829 17.53%, #1A0F2E 93.08%);
    }
}

.-earned {
    border-radius: rem(6) rem(62) rem(62) rem(6);
    width: rem(647);

    @include tablet {
        width: rem(423);

    }

    @include mobile {
        width: rem(506);
    }

    .statistics__image {
        width: rem(56);
        height: rem(43.2);

        @include tablet {
            width: rem(31);
            height: rem(27);
        }

        @include mobile {
            width: rem(46);
            height: rem(38);
        }

    }


    .information__amount__number {
        color: #F3AE03;
    }

    .information__amount__currency {
        color: #F3AE03;
        opacity: 100%;
    }

    &::after {
        background: linear-gradient(100.27deg, rgba(200, 141, 12, 0.5) 17.53%, rgba(43, 202, 149, 0.5) 93.08%);
        border-radius: rem(62) rem(62) rem(62) rem(62);
    }

    &::before {
        border-radius: rem(62) rem(62) rem(62) rem(62);
        background: linear-gradient(100.27deg, #1F131E 17.53%, #121828 93.08%);
    }
}

.exchange-first {
    margin-left: rem(16);

    @include tablet {
        margin-left: rem(6);
    }
}

.reward-button {
    margin-left: rem(16);
    padding: rem(17) rem(24) rem(17) rem(24);
    border-radius: rem(38);
    opacity: 20%;

    @include tablet {
        padding: rem(8) rem(8) rem(7) rem(8);
        margin-left: rem(6);
    }

    @include mobile {
        padding: rem(9) rem(10) rem(8.5) rem(10);
        margin-left: rem(10);
    }

    span {
        font-size: rem(18);
        color: color-constants-white(100);

        @include tablet {
            font-size: rem(11);
        }

        @include mobile {
            font-size: rem(13);
        }
    }
}