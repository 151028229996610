@use 'sass:math';
@use '@/assets/styles' as *;


.exchange{
    padding: rem(11.5) rem(12) rem(11.5) rem(12);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.22) 0%, rgba(0, 0, 0, 0.13) 100%);
    border: rem(1) solid rgba(255, 255, 255, 0.08);
    border-radius: rem(6);

    @include tablet{
        padding: rem(8.5) rem(9) rem(8.5) rem(12);
    }

    &__currency{
        font-size: rem(16);
        @include font-scraft-bold;
        line-height: 124%;
        color: #F3AE03;
        margin-bottom: rem(3);

        @include tablet{
            font-size: rem(10);
        }

        @include mobile{
            font-size: rem(13);
        }
    }

    div{
        display: flex;
    }

    &__main{
        @include font-scraft-regular;
        font-size: rem(10);
        color: rgba(255, 255, 255, 0.64);

        @include tablet{
            font-size: rem(8);
        }

    }
}