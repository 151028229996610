@use '@/assets/styles' as *;

.calculator-range {
    position: relative;
    padding: rem(20) 0 rem(35) 0;

    .calculator-track {
        width: 100%;
        height: rem(4);
        background-color: color-background(200);
        border-radius: rem(100);
    }

    .calculator-thumb {
        position: relative;
        width: rem(12);
        height: rem(12);
        background-color: color-constants-white(100);
        border-radius: 100%;
        @include cursor-pointer;

        &::before {
            content: '';
            position: absolute;
            width: rem(32);
            height: rem(32);
            top: 50%; left: 50%;
            transform: translate(-50%, -50%);
        }

        > div {
            position: absolute;
            top: 0; left: 0;
            width: 100%; height: 100%;
            transition: transform .3s ease;

            @keyframes wave {
                to {
                    transform: scale(2);
                    opacity: 0;
                }
            }
    
            &::after {
                content: '';
                position: absolute;
                top: 0; left: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background-color: color-constants-white(64);
            }
    
            &::before {
                position: absolute;
                pointer-events: none;
                content: attr(data-value);
                top: calc(100% + rem(7));
                left: 50%;
                transform: translateX(-50%);
                width: rem(28);
                height: rem(24);
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
                background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNiAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00LjMzMzMzIDI0LjA5M0MxLjk0MDEgMjQuMDkzIDAgMjIuMTAzMiAwIDE5LjY0ODZWOC41Mzc0NkMwIDYuMDgyODYgMS45NDAxIDQuMDkzMDIgNC4zMzMzMyA0LjA5MzAySDguOTU4NTVMMTIuMjk2NSAwLjc4OTI1NUMxMi42ODYyIDAuNDAzNTc4IDEzLjMxMzggMC40MDM1NzggMTMuNzAzNSAwLjc4OTI1NUwxNy4wNDE1IDQuMDkzMDJIMjEuNjY2N0MyNC4wNTk5IDQuMDkzMDIgMjYgNi4wODI4NiAyNiA4LjUzNzQ2VjE5LjY0ODZDMjYgMjIuMTAzMiAyNC4wNTk5IDI0LjA5MyAyMS42NjY3IDI0LjA5M0g0LjMzMzMzWiIgZmlsbD0iIzI0QTg3QyIvPgo8L3N2Zz4K);
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: rem(12);
                padding-top: 3px;
                padding-right: 1px;
                @include font-gcraft-semibold;
                color: color-constants-white(100);
            }
        }

        &:hover {
            > div {
                transform: scale(1.1);
                &::after {
                    animation: wave 1s ease infinite;
                }
            }
        }

        
    }

    .range-mark {
        position: relative;
        @include font-scraft-regular;
        top: 0;
        margin-top: 0 !important;
        color: color-constants-white(100);
        opacity: 0.4;
        font-size: rem(8);
        height: rem(10);
        transform: translateX(1px);

        &::before {
            content: '';
            position: absolute;
            top: calc(100% + rem(3));
            left: 50%;
            transform: translateX(-50%);
            width: 1px;
            height: rem(4);
            background: color-constants-white(100);
            opacity: 0.4;
            border-radius: 1px;
        }
    }

}