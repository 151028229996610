@use 'sass:math';
@use '@/assets/styles' as *;

.collect-button{
    padding: rem(17) rem(24) rem(17) rem(24);
    border-radius: rem(38);
    opacity: 20%;
    border: rem(1) solid #E7E7EE; // заменить
    cursor: pointer; // Нужен ли?

    span{
        @include font-scraft-semibold;
        font-size: rem(18);
        color: constants-white(100);
    }
}