@use '@/assets/styles' as *;

.linear-border {
    position: absolute;
    width: 100%;
    height: auto;
    @include containImage;
    z-index: 1;
    pointer-events: none;
    transform: translateZ(1px);

    &.border-top {
        top: -10px; 
    }

    &.border-bottom {
        bottom: -10px;
    }
}

.c-background {
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    @include coverImage;
}