@use '@/assets/styles' as *;

.roadmap {
    position: relative;
    height: 100% !important;
    --content-offset-x: #{rem(200)};
    @include laptop-lg { --content-offset-x: #{rem(70)}; }
    @include tablet { --content-offset-x: #{rem(32)}; }

    &__content {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include laptop-lg {
            padding-top: rem(100);
        }
        @include tablet {
            padding-top: 0;
        }

        &::before {
            content: '';
            position: absolute;
            width: rem(200);
            height: 100%;
            top: 0; right: 0;
            background: linear-gradient(270deg, #000000 0%, rgba(0, 0, 0, 0.47) 41.82%, rgba(0, 0, 0, 0) 92.23%);
            transform: translateZ(1px);
            z-index: 1;
            pointer-events: none;
        }

        .content__header {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            padding-left: var(--content-offset-x);

            h2 {
                font-size: rem(43);
                line-height: 116%;
                color: color-constants-white(100);
            }

            .header__tabs {
                margin-left: rem(41);
                display: flex;
                justify-content: flex-start;
                align-items: center;

                > * {
                    font-size: font(22);
                    line-height: 120%;
                    color: color-primary(600);
                    padding: rem(10) rem(16);
                    border-radius: rem(38);
                    transition: background-color .5s ease, color .5s ease, .3s transform ease, opacity .3s ease;
                    &:not(:first-child) { margin-left: rem(10); }
                    &.-active {
                        color: color-constants-white(100);
                        background-color: color-primary(300);
                    }
                    &:hover {
                        transform: translateY(-3px);
                        opacity: .7;
                    }
                }
            }
        }

        .content__wrappers {
            position: relative;
            margin-top: rem(42);
            width: 100%;
            height: rem(500);
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .content__container {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0; left: 0;
                pointer-events: none;
            }


            .list {
                opacity: 0 !important;
                user-select: none !important;
                pointer-events: none !important;
                visibility: hidden !important;
                transform: translateY(100%) !important;
                transition: opacity .3s ease, visibility .5s ease, transform .5s ease;
                &.-active {
                    opacity: 1 !important;
                    user-select: auto !important;
                    pointer-events: all !important;
                    visibility: visible !important;
                    transform: none !important;
                }
                
                &__content {
                    display: grid;
                    grid-template: 1fr / repeat(4, 1fr) ;
                    column-gap: rem(64);
                    padding: 0 var(--content-offset-x);
                    overflow-x: scroll !important;
                }

                &__track {
                    width: calc(100% - var(--content-offset-x) * 2);
                    left: var(--content-offset-x);
                    bottom: 6px;
                    height: 1.5px !important;
                    background: color-constants-white(8);
                }

                &__thumb {
                    height: 5px !important;
                    background: color-constants-white(100);
                    left: 0; top: -2.5px;
                    border-radius: 9px;
                }


                &__quartal {
                    width: rem(473);

                    h5 {
                        font-size: rem(40);
                        color: color-constants-white(100);
                        line-height: 100%;
                        margin-bottom: rem(24);
                        display: flex;
                        align-items: flex-start;

                        span {
                            font-size: font(18);
                            color: color-constants-white(64);
                            margin-left: rem(11);
                            line-height: 100%;
                            margin-top: 2px;
                        }
                    }

                    .stcheckbox {
                        margin-top: rem(8);
                    }
                } 
            }
        }
    }

    &__background {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        .background_1 {
            transition: opacity .5s ease;
            opacity: 0;
        }
    }
}

// animation
.roadmap {
    .roadmap__content > * {
        transition: .75s transform ease, .75s opacity;
        opacity: 0;
        transform: translateY(rem(200));
    }
}

.screen-scroll__slides {
    &.-active-id-roadmap {
        .roadmap {
            .roadmap__content > * {
                opacity: 1;
                transform: none;
            }

            @for $i from 1 through 4 {
                .roadmap__content > :nth-child(#{$i}) {
                    transition: 1s transform #{$i / 5}s ease, 1s opacity #{$i / 5}s;
                }
            }
            .background_1 {
                opacity: 1;
            }
        }
    }
}