@use "./styles/colors" as *;
@use "./styles/grid" as *;
@use "./styles/mixins" as *;
@use "./styles/typography" as *;
@use "./styles/functions" as *;
@use "./styles/cursor" as *;

@import "./styles/normalize";
@import "./fonts/stylesheet.css";

:root {
  @each $name, $color in $tokens {
    --color-#{$name}: #{$color};
  }
  @each $name, $font in $fonts {
    --font-size-#{$name}: #{$font};
  }
  @each $name, $point in $breakpoints {
    --breakpoint-#{$name}: #{$point};
  }
  @each $name, $size in $breakpoints-fonts {
    --breakpoint-font-#{$name}: #{$size};
  }
}

html {
  font-size: var(--breakpoint-font-xlg);
  color: color-text(50);
  background: color-background(50);
  @include font-scraft-regular;
  @include setScrollbar;
  @include generate-grid;
  @include cursor-default;
  scroll-behavior: smooth;
}

html,
body,
#root {
  height: 100%;

  // // ------------------------------------------
  // @include mobile {
  //   position: relative;
  //   max-height: 100%;
  //   overflow: hidden;
  // }
  // // ------------------------------------------

}

img {
  user-select: none;
}

h1, h2, h3, h4, h5, h6 {
  @include font-gcraft-semibold;
  letter-spacing: -0.01em;
}

.offset-y {
  padding-top: rem(200);
  padding-bottom: rem(200);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smooth: grayscale;
}

a {
  @include cursor-pointer;
}