@use '@/assets/styles' as *;

.park {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 1;
    height: 100vh !important;

    .border-bottom {
        transform: translateZ(1px);
    }

    &__content {
        position: relative;
        width: 100%;
        height: 100%;
        max-width: rem(1520);
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @include laptop-lg {
            max-width: rem(1220);
        }

        @include tablet {
            max-width: rem(920);
        }


        .content__header {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            position: absolute;
            top: calc(50% - rem(250));
            left: 50%;
            transform: translateX(-50%);
            @include mobile {
                top: calc(50% - rem(350));
            }

            h2 {
                font-size: rem(96);
                line-height: 115%;
                color: color-constants-white(100);

                @include mobile {
                    font-size: rem(80);
                }
            }

            p {
                font-size: rem(24);
                font-weight: 400;
                line-height: 124%;
                color: color-constants-white(100);
                max-width: rem(460);
                text-align: center;
                margin-top: rem(30);

                span {
                    color: color-secondary(500);
                }
            }
        }

        .content__name {
            margin-top: rem(-50);
            @include laptop-lg {
                margin-top: rem(-80);
            }
            @include mobile {
                margin-left: rem(32);
                margin-top: rem(-300);
            }

            .text {
                font-size: 2rem;
                line-height: 124%;
                color: color-constants-white(100);
                max-width: rem(380);
            }

            .name__stats {
                display: flex;
                justify-content: flex-start;
                align-items: stretch;
                margin-top: rem(48);

                .stats__card {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: rem(20);
                    color: color-constants-white(100);
                    border: 1px solid rgba(255, 255, 255, .16);
                    border-radius: rem(16);
                    width: rem(124);

                    &:not(:first-child) {
                        margin-left: rem(12);
                    }

                    .icon {
                        width: rem(36);
                        height: rem(36);
                    }

                    p {
                        text-align: center;
                        margin-top: rem(6);
                        font-size: font(12);
                    }
                }
            }
        }

        .content__description {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            @include mobile {
                top: calc(50% - rem(150));
                left: rem(32);
            }

            .text {
                margin-top: rem(32);
                font-size: font(24);
                line-height: 124%;
                font-weight: 400;
                color: color-constants-white(100);
                max-width: rem(410);

            }

            .photos {
                display: grid;
                grid-template: 1fr / repeat(4, 1fr);
                gap: rem(12);
                margin-top: rem(48);

                &__preview {
                    width: rem(64);
                    height: rem(64);
                    @include coverImage;
                    overflow: hidden;
                    border-radius: 100%;
                }
            }

            .subtext {
                margin-top: rem(48);
                font-size: font(18);
                line-height: 124%;
                font-weight: 400;
                color: color-constants-white(64);
                letter-spacing: -0.03em;
                max-width: rem(376);
            }
        }
    }


    &__background {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: -1;

        .background_1, .background_2, .background_3 {
            transition: opacity .75s ease;
        }

        .background_2 {
            opacity: 0;
        }
        .background_3 {
            opacity: 0;
        }


        .background__land {
            position: absolute;
            width: rem(1600);
            height: rem(1600);
            @include containImage;
            right: 50%;
            bottom: 50%;
            transition: .75s transform ease;
            transform: translate(50%, 100%);
            @include mobile {
                width: rem(1000);
                height: rem(1000);
            }
        }

    }
}

// animation
.park {
    @for $i from 1 through 5 {
        .content__name > :nth-child(#{$i}) {
            transition: .75s transform #{$i / 20}s ease, .75s opacity #{$i / 20}s;
        }

        .content__description > :nth-child(#{$i}) {
            transition: .75s transform #{$i / 20}s ease, .75s opacity #{$i / 20}s;
        }
    }
    .content__name > * {
        transform: translateY(rem(100));
        opacity: 0;
    }

    .content__header {
        > * {
            transition: .75s transform ease, .75s opacity;
            transform: translateY(rem(-200));
            opacity: 0;
        }
    }

    .content__description > * {
        opacity: 0;
    }
}


.screen-scroll__slides {
    &.-active-id-park {
        .park {
            .background__land { transform: scale(1.4) translate(rem(700), rem(1100)); }
            @include laptop-lg { .background__land { transform: scale(1.4) translate(rem(750), rem(1100)); } }
            @include tablet { .background__land { transform: scale(1.4) translate(rem(800), rem(1100)); } }
            @include mobile { .background__land { transform: scale(1.4) translate(rem(520), rem(630)); } }
        }
        .content__header > * {
            opacity: 1;
            transform: none;
        }

    }
    &.-active-id-park2 {
        .park {
            .background_1 { opacity: 0; }
            .background_2 { opacity: 1; }
            .background__land { transform: scale(.9) translate(rem(1200), rem(950)); }
            @include laptop-lg { .background__land { transform: scale(.9) translate(rem(1300), rem(1050)); } }
            @include tablet { .background__land { transform: scale(.9) translate(rem(1450), rem(1150)); } }
            @include mobile { .background__land { transform: scale(.9) translate(rem(620), rem(950)); } }
        }
        .content__name > * {
            transform: none;
            opacity: 1;
        }
    }
    &.-active-id-park3 {
        .park {
            .background_1 { opacity: 0; }
            .background_3 { opacity: 1; }
            .background__land { transform: scale(1.2) translate(rem(1000), rem(900)); }
            @include laptop-lg { .background__land { transform: scale(1.2) translate(rem(1100), rem(900)); } }
            @include tablet { .background__land { transform: scale(1.2) translate(rem(1150), rem(950)); } }
            @include mobile { .background__land { transform: scale(1.2) translate(rem(550), rem(850)); } }
        }
        .content__description > * {
            opacity: 1;
            transform: none;
        }
    }

    .-scrolled {
        .park {
            .background_1 { opacity: 0; }
            .background_3 { opacity: 1; }
            .background__land { transform: scale(1) translate(-5%, -20%); }
        }
        .content__description > * {
            transform: translateY(rem(-600));
        }
    }
}