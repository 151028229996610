@use '@/assets/styles' as *;

.calculator-modal {
    background: color-background(100);
    border-radius: rem(20);
    border: 1px solid color-background(200);
    padding: rem(40) rem(40) rem(56) rem(40);

    > :not(:first-child) {
        margin-top: rem(32);
    }


    .calculator__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        color: color-constants-white(100);
        font-size: font(24);
        @include font-scraft-regular;

        h5 {
            display: flex;
            align-items: center;

            .icon {
                width: rem(32);
                height: rem(32);
                margin-right: rem(12);
            }
        }


        .cross {
            line-height: 100%;
            position: relative;
            transition: opacity .3s ease;
            @include cursor-pointer;

            &::before {
                content: '';
                position: absolute;
                width: rem(32);
                height: rem(32);
                top: 55%; left: 50%;
                transform: translate(-50%, -50%);
            }

            &:hover {
                opacity: .5;
            }
        }
    }

    h6 {
        @include font-scraft-regular;
        color: color-constants-white(100);
        line-height: 124%;
        font-size: rem(18);
    }

    .calculator__select {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;

        .select-wrapper {
            display: flex;
            justify-content: flex-start;
            margin-top: rem(16);
            gap: rem(8);
        }
    }

    .calculator__range {
        h6 {
            margin-bottom: rem(16);
        }
    }

    .calculator__footer {
        margin-top: rem(18);

        .footer__wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        .divider {
            width: 1px;
            height: rem(48);
            background: color-constants-white(24);
            margin: 0 rem(24);
        }

        .count {
            display: flex;
            align-items: flex-start;
            margin-top: rem(2);
            > :first-child {
                @include font-scraft-regular;
                font-size: rem(64);
                line-height: 100%;
                color: color-secondary(600);
            }

            > :last-child {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: rem(9);
                margin-left: rem(9);
                color: color-constants-white(64);
                letter-spacing: 0.06em;
                line-height: 100%;
                @include font-scraft-black;

                > :first-child {
                    font-size: rem(12);
                }

                > :last-child {
                    font-size: rem(9);
                    margin-top: rem(-2);
                }
            }
        }

        .price {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            > :first-child {
                @include font-scraft-regular;
                font-size: rem(24);
                line-height: 100%;
                color: color-secondary(600);

                span {
                    font-size: rem(19);
                }
            }

            > :last-child {
                padding-top: rem(9);
                color: color-constants-white(64);
                line-height: 100%;
                @include font-scraft-regular;
            }
        }
    }

    
}