@use '@/assets/styles' as *;

.genesis {
    position: relative;
    color: color-constants-white(100);
    z-index: 1;


    &__background {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        > * {
            position: absolute;
            top: 0; left: 0;
            width: 100%;
            height: 100%;
        }

        .background_1 {
            background: radial-gradient(100% 100% at 50% 100%, #0A362F 0%, #000000 70%, #000000 100%);
        }
        .background_2 {
            background: radial-gradient(110% 110% at 50% 110%, #0A362F 0%, #000000 100%);
        }
    }

    &__slides {
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 100%;
        overflow: hidden;
        z-index: 1;

        .slide {
            user-select: none;
            position: absolute;
            top: 0; left: 0;
            width: 100%; height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            &__content {
                max-width: rem(1138);
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                width: auto;
                height: auto;
                min-height: auto;
                @include tablet {
                    max-width: rem(800);
                }
                @include mobile {
                    max-width: rem(500);
                }
            }

            h2 {
                font-size: font(43);
                line-height: 132%;
                color: color-constants-white(100);

                @include tablet {
                    display: none;
                }

                span {
                    position: relative;
                    &::before {
                        content: '';
                        position: absolute;
                        top: 0; left: 0;
                        transform: translate(90%, -25%);
                        width: rem(32);
                        height: rem(32);
                        background-image: url(./assets/mint.png);
                        background-size: contain;
                    }
                }
            }
        }

        .slide_1 {
            .slide__grid {
                display: grid;
                grid-template: 1fr / repeat(4, 1fr);
                row-gap: rem(60);
                column-gap: rem(15);
                margin-top: rem(40);

                @include tablet {
                    grid-template: 1fr / repeat(3, 1fr);
                    row-gap: rem(40);
                }

                @include mobile {
                    grid-template: 1fr / repeat(2, 1fr);
                    row-gap: rem(32);
                }

                .grid__content {
                    > div {
                        font-size: font(16);
                        color: color-constants-white(64);
                        line-height: 96%;
                        font-weight: 400;
                    }

                    h5 {
                        font-size: font(24);
                        line-height: 116%;
                        font-weight: 400;
                        color: color-constants-white(100);
                        margin-top: rem(10);
                    }

                    p {
                        font-size: font(18);
                        line-height: 132%;
                        font-weight: 400;
                        color: color-constants-white(64);
                        margin-top: rem(16);
                        max-width: 90%;
                    }
                }

            }
        }

        .slide_2 {
            pointer-events: none;
            justify-content: flex-end;
            padding-right: rem(840);
            margin-top: rem(-160);

            @include laptop-lg {
                padding-right: rem(600);
                margin-top: rem(-25);
            }

            @include tablet {
                margin-top: 0;
                padding-right: rem(225);
            }

            @include mobile {
                padding-right: rem(85);
                padding-top: rem(100);
            }

            .slide__grid {
                display: grid;
                grid-template: 1fr / repeat(5, auto);
                column-gap: rem(40);
                margin-top: rem(40);

                @include mobile {
                    grid-template: 1fr / repeat(3, auto);
                    row-gap: rem(60);
                }

                .grid__content {
                    position: relative;
                    padding-left: rem(16);

                    &::before, &::after {
                        content: '';
                        position: absolute;
                        top: 0; left: 0;
                        background: color-constants-white(100);
                    }

                    &::before {
                        width: 8px;
                        height: 8px;
                        border-radius: 100%;
                        transform: translateX(-50%);
                    }

                    &::after {
                        width: 1px;
                        height: 100vh;
                        opacity: 0.2;
                    }

                    > div {
                        font-size: font(16);
                        color: color-constants-white(64);
                        line-height: 96%;
                        font-weight: 400;
                    }

                    h5 {
                        font-size: font(28);
                        line-height: 108%;
                        font-weight: 400;
                        color: color-constants-white(100);
                        margin-top: rem(35);
                        display: flex;
                        align-items: center;

                        svg {
                            width: rem(20);
                            height: auto;
                            margin-left: 4px;
                            margin-top: 2px;
                        }
                    }

                    p {
                        font-size: font(16);
                        color: color-constants-white(64);
                        line-height: 96%;
                        font-weight: 400;
                        margin-top: rem(10);
                    }
                }
            }
        }
    }

    .calculator {
        padding: rem(32) rem(24);
        display: flex;
        flex-direction: column;
        background: color-secondary(50);
        border: 1px solid color-secondary(100);
        border-radius: rem(20);
        @include tablet {
            padding: rem(24) rem(16);
        }
        @include mobile {
            padding: rem(16);
            max-width: 95%;
        }

        @include tablet {
            transition: .75s transform ease, .75s opacity ease !important;
        }

        p {
            font-size: font(18);
            color: color-constants-white(100);
            line-height: 132%;
            font-weight: 400;
        }

        .button {
            margin-top: rem(32);
            @include tablet {
                font-size: font(16);
                margin-top: rem(16);
                width: 100%;
            }
        }
    }

}

// animation
.genesis {

    .background_1, .background_2 {
        transition: .75s opacity ease;
    }
    .background_1 {
        opacity: 1;
    }
    .background_2 {
        opacity: 0;
    }

    h2 {
        transition: .75s transform, .75s opacity;
        transform: translateY(100vh);
        opacity: 0;
    }

    @for $i from 1 through 10 {
        .slide__grid > :nth-child(#{$i}) {
            transition: .75s transform #{$i / 50}s, .75s opacity #{$i / 50}s;
            transform: translateY(100vh);
            opacity: 0;
        }
    }


}
.screen-scroll__slides {
    &.-active-id-genesis {
        .genesis {
            .slide_1 {
                .slide__grid > *, h2 {
                    transform: none;
                    // filter: none;
                    opacity: 1;
                }
            }
            
        }
    }
    &.-active-id-genesis2, .-scrolled {
        .genesis {
            .background_1 { opacity: 0; }
            .background_2 { opacity: 1; }
        }

        .slide_1 {
            .slide__grid > *, h2 {
                transform: translateY(-100vh);
                opacity: 0;
            }

            .calculator {
                transform: translateY(-100%);
                opacity: 1;
                @include laptop-lg {
                    transform: translateY(calc(-100% + rem(50)));
                }
                @include tablet {
                    transform: translate(rem(-330), rem(-430));
                }
                @include mobile {
                    transform: translate(rem(-265), rem(-520));
                }
            }
        }

        .slide_2 {
            .slide__grid > *, h2 {
                transform: none;
                // filter: none;
                opacity: 1;
            }
        }
    }
}