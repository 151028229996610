@use '@/assets/styles' as *;

.footer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .logo {
            width: rem(400);
            height: auto;
            color: white;
        }

        .content__buttons {
            display: flex;
            justify-content: flex-start;
            margin-top: rem(37);
            > :not(:first-child) {
                margin-left: rem(16);
            }
        }

        .content__socials {
            display: flex;
            justify-content: flex-start;
            gap: rem(40);
            margin-top: rem(58);

            a {
                width: rem(32);
                height: rem(32);
                &:hover {
                    .icon {
                        opacity: .7;
                        transform: translateY(-3px);
                    }
                }

                .icon {
                    width: 100%;
                    height: 100%;
                    transition: transform .3s ease, opacity .3s;
                }
            }
        }
    }

    .footer__copyright {
        position: absolute;
        font-size: font(12);
        line-height: 136%;
        color: color-constants-white(64);
        text-align: center;
        left: 50%; bottom: rem(118);
        transform: translateX(-50%);
    }

    &__background {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0; left: 0;
        z-index: -1;
        @include coverImage;

        .border-top {
            height: rem(300);
            top: 0;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0; left: 0;
            width: 100%;
            height: 100%;
            background: color-background(50);
            opacity: 0.5;
          }
    }
}

// animation
$toAnimate: (
  '.footer__content > :nth-child(1)',
  '.footer__content > :nth-child(2)',
  '.footer__content > :nth-child(3)',
);

.slide {
  $i: 0;
  @each $class in $toAnimate {

    #{$class} {
      transform: translate3d(0, rem(-100), 0) scale(1.2);
      opacity: 0;
      transition: 0s opacity .5s, 0s transform .5s, 0s filter .5s;
    }

    &.-active {
      #{$class} {
        transform: none;
        opacity: 1;
        transition: 1s opacity #{$i / 5}s, 1s transform #{$i / 5}s, 1s filter #{$i / 5}s;
      }
    }
  
    $i: $i + 1;
  }


  .footer__background {
    transform: scale(1.25);
    transition: .5s transform ease;
  }
  &.-active {
    .footer__background {
      transition: 4s transform ease;
      transform: none;
    }
  }
}