@use 'sass:math';
@use '@/assets/styles' as *;


.my-space{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    color: var(--color-constants-white-100);
    background: radial-gradient(289.28% 133.9% at 50% 133.9%, #201041 18%, #110920 43.29%);
    padding: rem(162) rem(70) rem(70) rem(70);
    //заменить цвет

    @include mobile{
        padding: rem(162) rem(35) rem(70) rem(35);
    }

}
