@use '@/assets/styles' as *;

.screen-scroll {
    position: relative;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;

    &__slides {
        width: 100%;
        height: 100%;
        will-change: transform;

        .slide {
            min-height: 100vh;
            height: 100%;
            max-height: 100%;

            &.-afterchanging {
                &.inner-scroll {
                    overflow-y: auto;
                }
            }

            &.inner-scroll {
                overflow-y: hidden;
                -ms-overflow-style: none;
                scrollbar-width: none; 
                &::-webkit-scrollbar {
                    display: none;
                }
            }

            .inner-scroll__content {
                position: relative;
                overflow: hidden;
                > * {
                    width: 100%;
                    min-height: 100%;
                    height: 100%;
                }
            }

            > * {
                width: 100%;
                min-height: 100%;
                height: auto;
            }
        }
    }

    &__pagination {
        position: fixed;
        z-index: 2;
        top: 50%;
        transform: translateY(-50%);
        right: rem(25);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include cursor-pointer;
        @include tablet {
            right: rem(16);
        }

        &::after {
            content: '';
            position: absolute;
            top: 0; left: 50%;
            width: rem(250);
            height: 100%;
            transform: translateX(-50%);
            z-index: -1;
            @include tablet {
                pointer-events: none;
            }
        }

        [data-id="tooltip"] {
            pointer-events: all !important;
            white-space: nowrap;
            padding: rem(7) rem(12);
            font-size: font(16);
            background: rgba(255, 255, 255, .5);
            line-height: 100%;
            margin-left: -5px !important;
            &::after {
                display: none;
                border-left-color: rgba(255, 255, 255, .5) !important;
            }
            @include tablet {
                display: none;
            }
        }

        .pagination-wrapper {
            position: relative;
            &::before {
                position: absolute;
                content: '';
                width: 20px;
                height: 100%;
                top: 0; left: 50%;
                transform: translateX(-50%);
            }
            &:hover {
                .pagination-button {
                    background-color: #BABABA;
                    opacity: 1;
                    @include tablet {
                        background: #D9D9D9;
                        opacity: .12;
                    }
                }
                [data-id="tooltip"] {
                    background: rgba(255, 255, 255, 1);
                }
            }

            &.-active {
                .pagination-button {
                    opacity: 1;
                    transform: scaleX(1.1);

                    @include tablet {
                        &:hover {
                            background: #D9D9D9;
                            opacity: 1;
                        }
                    }
                }

                [data-id="tooltip"] {
                    background: rgba(255, 255, 255, 1) !important;
                }
            }
        }

        .pagination-button {
            position: relative;
            width: 4px;
            height: rem(32);
            border-radius: 2px;
            background: #D9D9D9;
            opacity: .12;
            transition: opacity .5s ease, transform .5s ease, background-color .3s ease;
            pointer-events: none;
        }

        > :not(:first-child) {
            margin-top: 5px;
        }
    }
}