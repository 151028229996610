@use 'sass:math';
@use '@/assets/styles' as *;

.tree-nfts{
    margin-bottom: rem(112);
    width: rem(1300);
    z-index: 100;


    @include tablet{
        width: rem(853);
    }

    @include mobile{
        width: rem(506);
    }

    &__title{
        margin-bottom: rem(25);
        @include font-scraft-regular;
        font-size: rem(24);
        display: flex;
        align-items: center;

        @include tablet{
            font-size: rem(20);
        }

        @include mobile{
            font-size: rem(17);
        }

        &__image{
            margin-top: rem(5);
            margin-left: rem(13);
            width: rem(24);
            height: rem(24);
            border-radius: rem(36);
            background-color: rgba(255, 255, 255, 0.8);
            // text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;

            @include mobile{
                width: rem(20);
                height: rem(20);
            }

            span{
                font-family: "Craftwork Sans", serif;
                font-weight: 600;
                font-size: rem(15);
                color: color-background(50);
                margin-bottom: rem(1);

                @include mobile{
                    font-size: rem(12);
                }
            }
        }
    }

    &__cards{
        display: flex;
        flex-wrap: wrap;
        gap: rem(17.5);

        @include tablet{
            column-gap: rem(8);
        }

        @include mobile{
            column-gap: rem(26);
        }
    }
}