@use 'sass:math';
@use '@/assets/styles' as *;

.farming{
    margin-bottom: rem(90);

    &__first{
        display: flex;
        margin-bottom: rem(16);

        &__title{
            margin-top: rem(-3);
            @include font-scraft-regular;
            font-size: rem(24);
            margin-right: rem(13);

            @include tablet{
                font-size: rem(20);
                margin-top: rem(0);
            }
        }
    }

    

    .white-ballon{
        position: absolute;
        right: rem(-190);
        top: rem(80);
        width: rem(265);
        height: rem(250);
        pointer-events: none;

        @include tablet{
            transform: translate(rem(188), rem(3));
        }

        @include mobile{
            width: rem(220);
            height: rem(208);
            top: rem(180);
            right: rem(-150);
        }
    }
        
    &__cards{
        display: flex;
        position: relative;


        @include mobile{
            flex-wrap: wrap;
       }
    }
}