@font-face {
    font-family: 'Craftwork Grotesk';
    src: url('CraftworkGrotesk-Heavy.woff2') format('woff2'),
        url('CraftworkGrotesk-Heavy.woff') format('woff'),
        url('CraftworkGrotesk-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Craftwork Grotesk';
    src: url('CraftworkGrotesk-Regular.woff2') format('woff2'),
        url('CraftworkGrotesk-Regular.woff') format('woff'),
        url('CraftworkGrotesk-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Craftwork Grotesk';
    src: url('CraftworkGrotesk-Heavy.woff2') format('woff2'),
        url('CraftworkGrotesk-Heavy.woff') format('woff'),
        url('CraftworkGrotesk-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Craftwork Grotesk';
    src: url('CraftworkGrotesk-Medium.woff2') format('woff2'),
        url('CraftworkGrotesk-Medium.woff') format('woff'),
        url('CraftworkGrotesk-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Craftwork Grotesk';
    src: url('CraftworkGrotesk-Bold.woff2') format('woff2'),
        url('CraftworkGrotesk-Bold.woff') format('woff'),
        url('CraftworkGrotesk-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Craftwork Grotesk';
    src: url('CraftworkGrotesk-Heavy.woff2') format('woff2'),
        url('CraftworkGrotesk-Heavy.woff') format('woff'),
        url('CraftworkGrotesk-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Craftwork Grotesk Semi';
    src: url('CraftworkGrotesk-SemiBold.woff2') format('woff2'),
        url('CraftworkGrotesk-SemiBold.woff') format('woff'),
        url('CraftworkGrotesk-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Craftwork Grotesk Semi';
    src: url('CraftworkGrotesk-SemiBold.woff2') format('woff2'),
        url('CraftworkGrotesk-SemiBold.woff') format('woff'),
        url('CraftworkGrotesk-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Craftwork Grotesk';
    src: url('CraftworkGrotesk-Regular.woff2') format('woff2'),
        url('CraftworkGrotesk-Regular.woff') format('woff'),
        url('CraftworkGrotesk-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Craftwork Grotesk';
    src: url('CraftworkGrotesk-Medium.woff2') format('woff2'),
        url('CraftworkGrotesk-Medium.woff') format('woff'),
        url('CraftworkGrotesk-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Craftwork Grotesk';
    src: url('CraftworkGrotesk-Bold.woff2') format('woff2'),
        url('CraftworkGrotesk-Bold.woff') format('woff'),
        url('CraftworkGrotesk-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Craftwork Grotesk Semi';
    src: url('CraftworkGrotesk-SemiBold.woff2') format('woff2'),
        url('CraftworkGrotesk-SemiBold.woff') format('woff'),
        url('CraftworkGrotesk-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Craftwork Grotesk';
    src: url('CraftworkGrotesk-Medium.woff2') format('woff2'),
        url('CraftworkGrotesk-Medium.woff') format('woff'),
        url('CraftworkGrotesk-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Craftwork Grotesk';
    src: url('CraftworkGrotesk-Regular.woff2') format('woff2'),
        url('CraftworkGrotesk-Regular.woff') format('woff'),
        url('CraftworkGrotesk-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Craftwork Grotesk';
    src: url('CraftworkGrotesk-Bold.woff2') format('woff2'),
        url('CraftworkGrotesk-Bold.woff') format('woff'),
        url('CraftworkGrotesk-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}