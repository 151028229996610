@font-face {
    font-family: 'Craftwork Sans';
    src: url('CraftworkSans-Medium.woff2') format('woff2'),
        url('CraftworkSans-Medium.woff') format('woff'),
        url('CraftworkSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Craftwork Sans';
    src: url('CraftworkSans-Black.woff2') format('woff2'),
        url('CraftworkSans-Black.woff') format('woff'),
        url('CraftworkSans-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Craftwork Sans';
    src: url('CraftworkSans-Medium.woff2') format('woff2'),
        url('CraftworkSans-Medium.woff') format('woff'),
        url('CraftworkSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Craftwork Sans';
    src: url('CraftworkSans-Black.woff2') format('woff2'),
        url('CraftworkSans-Black.woff') format('woff'),
        url('CraftworkSans-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Craftwork Sans';
    src: url('CraftworkSans-Bold.woff2') format('woff2'),
        url('CraftworkSans-Bold.woff') format('woff'),
        url('CraftworkSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Craftwork Sans';
    src: url('CraftworkSans-Regular.woff2') format('woff2'),
        url('CraftworkSans-Regular.woff') format('woff'),
        url('CraftworkSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Craftwork Sans';
    src: url('CraftworkSans-Semibold.woff2') format('woff2'),
        url('CraftworkSans-Semibold.woff') format('woff'),
        url('CraftworkSans-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Craftwork Sans';
    src: url('CraftworkSans-Regular.woff2') format('woff2'),
        url('CraftworkSans-Regular.woff') format('woff'),
        url('CraftworkSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Craftwork Sans';
    src: url('CraftworkSans-Regular.woff2') format('woff2'),
        url('CraftworkSans-Regular.woff') format('woff'),
        url('CraftworkSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Craftwork Sans';
    src: url('CraftworkSans-Bold.woff2') format('woff2'),
        url('CraftworkSans-Bold.woff') format('woff'),
        url('CraftworkSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Craftwork Sans';
    src: url('CraftworkSans-Medium.woff2') format('woff2'),
        url('CraftworkSans-Medium.woff') format('woff'),
        url('CraftworkSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Craftwork Sans';
    src: url('CraftworkSans-Bold.woff2') format('woff2'),
        url('CraftworkSans-Bold.woff') format('woff'),
        url('CraftworkSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Craftwork Sans';
    src: url('CraftworkSans-Semibold.woff2') format('woff2'),
        url('CraftworkSans-Semibold.woff') format('woff'),
        url('CraftworkSans-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Craftwork Sans';
    src: url('CraftworkSans-Black.woff2') format('woff2'),
        url('CraftworkSans-Black.woff') format('woff'),
        url('CraftworkSans-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Craftwork Sans';
    src: url('CraftworkSans-Semibold.woff2') format('woff2'),
        url('CraftworkSans-Semibold.woff') format('woff'),
        url('CraftworkSans-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

