@use '@/assets/styles' as *;

.generation {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    @include mobile {
        align-items: center;
        padding-top: rem(100);
    }

    .income, .count {
        opacity: 0;
        transition: opacity .5s ease;
    }

    &__content {
        z-index: 1;

        .content__wrapper {
            margin-left: rem(200);
            @include laptop-lg {
                margin-left: rem(120);
            }
            @include mobile {
                margin-left: 0;
                padding: 0 rem(34);
                margin-top: rem(-400);
            }

            > :first-child {
                color: color-constants-white(100);
                font-size: rem(60);
                line-height: 116%;
                max-width: rem(400);
                @include mobile {
                    max-width: none;
                }
            }

            > :not(:first-child) {
                margin-top: rem(40);
            }


            > * {
                transform: translateY(rem(100));
                opacity: 0;
            }
            > :first-child {
                transition: .5s transform ease, .5s filter, .5s opacity;
            }
            > :nth-child(2) {
                transition: .5s transform .05s ease, .5s filter .05s, .5s opacity .05s;
            }
            > :last-child {
                transition: .5s transform .1s ease, .5s filter .1s, .5s opacity .1s;
            }
        }

        .content__title, .content__subtitle {
            position: absolute;
            top: 0; left: 50%;
            transform: translateX(-50%);
            font-weight: 400;
            color: color-constants-white(100);
            text-align: center;
            width: 100%;
            > span {
                display: inline-block;
                opacity: 0;
                transition: .5s opacity, .5s transform ease;
            }
        }

        .content__title {
            font-size: font(110);
            top: 30%;
            transform: translate(-50%, -50%);
            > span { transform: translateY(rem(-300)); position: relative; }
            @include mobile {
                font-size: rem(70);
            }

            span::before {
                content: attr(data-text);
                position: absolute;
                width: rem(660);
                color: color-constants-white(100);
                font-size: font(24);
                line-height: 116%;
                text-align: center;
                top: calc(100% + rem(22));
                left: 50%;
                transform: translateX(-50%);
                @include tablet {
                    max-width: rem(500);
                }
            }
        }

        .content__subtitle {
            top: calc(50% - rem(300));
            font-size: font(43);
            > span { transform: translateY(rem(-100)); }
            @include laptop-lg { 
                top: calc(50% - rem(200));
            }
            @include mobile {
                top: calc(50% - rem(350));
            }
        }

        .stats {
            display: flex;
            justify-content: flex-start;
            width: auto;

            > :not(:first-child) {
                margin-left: rem(16);
            }

            &__card {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: rem(17) rem(30);
                border-radius: rem(21);
                border: 1px solid rgba(255, 255, 255, .16);
                width: rem(140);


                .icon {
                    width: rem(40);
                    height: rem(40);
                }

                h5 {
                    margin-top: rem(10);
                    color: color-constants-white(100);
                    font-size: font(20);
                    line-height: rem(20);
                    font-weight: 400;
                }

                p {
                    margin-top: 4px;
                    color: color-constants-white(100);
                    font-size: font(14);
                    font-weight: 400;
                    opacity: 0.6;
                }
            }

        }
    }

    &__canvas {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
        background: radial-gradient(133.9% 133.9% at 50% 133.9%, #4B2697 0%, #000000 72.56%);
        z-index: -1;
        padding-top: rem(400);
        @include laptop-lg {
            padding-top: rem(350);
        }

        &::before {
            content: '';
            position: absolute;
            top: 0; left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, .7);
            z-index: 10;
            transition: .75s opacity;
        }

        &::after {
            content: '';
            position: absolute;
            left: 0; bottom: 0;
            width: 100%;
            height: rem(300);
            background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
          }

        .canvas__container {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;

            .nfts {
                display: flex;


                &__object {
                    position: relative;
                    width: rem(340);
                    transition: .75s transform ease;

                    @include laptop-lg {
                        width: rem(250);
                    }

                    @include tablet {
                        width: rem(200);
                    }
                }
            }
        }

        .nft {
            position: absolute;
            top: 0; left: 0;
            width: 100%;
            height: auto;
            svg {
                width: 100%;
                height: auto;
            }
        }

        .o-1 {
            z-index: 4;
            @include mobile {
                transform: scale(1.2) translate(rem(140), rem(350));
            }
        }
        .o-2 {
            z-index: 6;
            @include mobile {
                transform: scale(1.2) translate(rem(-20), rem(50));
            }
        }
        .o-3 {
            z-index: 2;
            @include mobile {
                transform: scale(1.2) translate(rem(20), rem(50));
            }
        }
        .o-4 {
            z-index: 5;
            @include mobile {
                transform: scale(1.2) translate(rem(-140), rem(350));
            }
        }
    }
}



// animation
.screen-scroll__slides {
    &.-active-id-generation {
        .generation {
            .content__title > span {
                opacity: 1;
                transform: none;
            }
            .o-1 { transform: scale(3) translate(calc(50% - rem(130)), calc(50% - rem(50))); }
            .o-2 { transform: scale(3) translate(calc(50% - rem(130)), calc(50% - rem(50))); }
            .o-3 { transform: scale(3) translate(calc(50% - rem(230)), calc(50% - rem(80))); }
            .o-4 { transform: scale(3) translate(calc(50% - rem(230)), calc(50% - rem(150))); }
            @include laptop-lg {
                .o-1 { transform: scale(3) translate(calc(50% - rem(70)), calc(50% - rem(30))); }
                .o-2 { transform: scale(3) translate(calc(50% - rem(70)), calc(50% - rem(30))); }
                .o-3 { transform: scale(3) translate(calc(50% - rem(170)), calc(50% - rem(50))); }
                .o-4 { transform: scale(3) translate(calc(50% - rem(170)), calc(50% - rem(130))); }
            }
            @include tablet {
                .o-1 { transform: scale(3) translate(calc(50% - rem(60)), calc(50% - rem(30))); }
                .o-2 { transform: scale(3) translate(calc(50% - rem(60)), calc(50% - rem(30))); }
                .o-3 { transform: scale(3) translate(calc(50% - rem(130)), calc(50% - rem(50))); }
                .o-4 { transform: scale(3) translate(calc(50% - rem(130)), calc(50% - rem(80))); }
            }

            @include mobile {
                .o-1 { transform: scale(3.5) translate(calc(50% - rem(70)), calc(50% - rem(30))); }
                .o-2 { transform: scale(3.5) translate(calc(50% - rem(70)), calc(50% - rem(30))); }
                .o-3 { transform: scale(3.5) translate(calc(50% - rem(140)), calc(50% - rem(50))); }
                .o-4 { transform: scale(3.5) translate(calc(50% - rem(140)), calc(50% - rem(80))); }
            }
        }
    }
    &.-active-id-generation2 {
        .generation {
            &__canvas::before {
                opacity: 0;
            }

            .count {
                opacity: 1;
            }

            .o-1 { transform: scale(1.1) translate(calc(50% + rem(350)), calc(50% - rem(350))); }
            .o-2 { transform: scale(1.1) translate(calc(50% + rem(300)), calc(50% - rem(300))); }
            .o-3 { transform: scale(1.1) translate(calc(50% - rem(80)), calc(50% - rem(500))); }
            .o-4 { transform: scale(1.1) translate(calc(50% - rem(120)), calc(50% - rem(480))); }

            @include laptop-lg {
                .o-1 { transform: scale(1.1) translate(calc(50% + rem(300)), calc(50% - rem(270))); }
                .o-2 { transform: scale(1.1) translate(calc(50% + rem(250)), calc(50% - rem(220))); }
                .o-3 { transform: scale(1.1) translate(calc(50% - rem(0)), calc(50% - rem(380))); }
                .o-4 { transform: scale(1.1) translate(calc(50% - rem(40)), calc(50% - rem(360))); }
            }

            @include tablet {
                .o-1 { transform: scale(1.1) translate(calc(50% + rem(280)), calc(50% - rem(260))); }
                .o-2 { transform: scale(1.1) translate(calc(50% + rem(230)), calc(50% - rem(220))); }
                .o-3 { transform: scale(1.1) translate(calc(50% + rem(30)), calc(50% - rem(350))); }
                .o-4 { transform: scale(1.1) translate(calc(50% + rem(0)), calc(50% - rem(330))); }
            }

            @include mobile {
                .o-1 { transform: scale(1.1) translate(calc(50% + rem(40)), calc(50% - rem(60))); }
                .o-2 { transform: scale(1.1) translate(calc(50% + rem(20)), calc(50% - rem(20))); }
                .o-3 { transform: scale(1.1) translate(calc(50% - rem(220)), calc(50% - rem(150))); }
                .o-4 { transform: scale(1.1) translate(calc(50% - rem(240)), calc(50% - rem(130))); }
            }

            .content__wrapper {
                > * {
                    // filter: none;
                    transform: none;
                    opacity: 1;
                }
            }
        }
    }
    &.-active-id-generation3 {
        .generation {
            .o-1, .o-2, .o-3, .o-4 { transform: translateY(calc(50% - rem(350))); }
            @include laptop-lg {
                .o-1, .o-2, .o-3, .o-4 { transform: translateY(calc(50% - rem(250))); }
            }
            @include mobile {
                .o-1 { transform: translate(calc(50% + rem(60)), calc(50% - rem(370))); }
                .o-2 { transform: translate(calc(50% - rem(130)), calc(50% - rem(80))); }
                .o-3 { transform: translate(calc(50% - rem(80)), calc(50% - rem(90))); }
                .o-4 { transform: translate(calc(50% - rem(280)), calc(50% - rem(380))); }
            }

            .content__subtitle > span {
                opacity: 1;
                transform: none;
            }
            .income {
                opacity: 1;
            }
            &__canvas::before {
                opacity: 0;
            }
        }
    }

    .-scrolled {
        .nfts__object {
            // filter: blur(30px);
        }
    }
}