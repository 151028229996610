@use "sass:map";
@use "mixins" as *;

@mixin font-scraft-regular {
  font-family: "Craftwork Sans", serif;
  font-weight: 100;
}
@mixin font-scraft-black {
  font-family: "Craftwork Sans", serif;
  font-weight: 900;
}
@mixin font-gcraft-semibold {
  font-family: "Craftwork Grotesk", serif;
  font-weight: 500;
}

@mixin font-scraft-semibold {
  font-family: "Craftwork Sans", serif;
  font-weight: 500;
}

@mixin font-scraft-bold {
  font-family: "Craftwork Sans", serif;
  font-weight: 700;
}



$fonts: (
  12: 0.75rem,
  14: 0.875rem,
  16: 1rem,
  18: 1.125rem,
  20: 1.25rem,
  22: 1.375rem,
  24: 1.5rem,
  28: 1.75rem,
  43: 2.6875rem,
  53: 3.3125rem,
  110: 6.875rem
);

@function font($name) {
  @if not map.get($fonts, $name) {
    @error '"#{$name}" is not a valid font value';
  }
  @return var(--font-size-#{$name});
} ;
