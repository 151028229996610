@use '@/assets/styles' as *;

.game {

    position: relative;
    background: color-background(50);
    z-index: 1;
    height: 100% !important;

    &__wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: 100%;
        overflow: hidden;

        @include tablet {
            flex-direction: column;
        }
    }

    &__title {
        position: absolute;
        top: calc(50% - rem(190));
        left: rem(200);

        @include laptop-lg {
            left: rem(100);
        }

        @include tablet {
            display: none;
        }
    }
    .text__title {
        display: none;
        @include tablet {
            display: inline-block;
            margin-bottom: rem(32);
            // transform: translateY(rem(-32));
            // margin-top: -100%;
        }
    }
    &__title, .text__title {
        h2 {
            color: color-constants-white(100);
            font-weight: 400;
            line-height: 96%;
            letter-spacing: -0.04em;
            font-size: font(53);
            max-width: 50%;
        }
    }

    &__content {
        position: relative;
        height: 100%;
        flex: 1;
        margin-left: rem(750);

        @include laptop-lg {
            margin-left: rem(400);
        }

        @include tablet {
            width: calc(100% - rem(64));
            margin-left: rem(16);
        }

        .content-border {
            position: absolute;
            bottom: 0; left: rem(16);
            width: 1px;
            height: 100%;
            background: rgba(255, 255, 255, .3);
        }

        .content__slides {
            position: absolute;
            top: 0; left: 0;
            width: 100%;
            height: auto;
            will-change: transform;
            transition: .75s transform ease;

            h3 {
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 8px;
                    height: 8px;
                    border-radius: 100%;
                    transform: translate(rem(-36), -50%);
                    background: color-constants-white(100);
                }
            }
        }

    }


    &__background {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        top: 0; left: 0;

        > * {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0; left: 0;
            @include coverImage;
        }

        .background__blur {
            opacity: 1;
            width: 150%;
            left: -25%;
            top: 0%;
            transform: translate3d(0, -50%, 1px);

            @include mobile {
                width: 500%;
                left: -200%;
            }


            img {
                width: 100% !important;
                height: auto !important;
            }
        }

        .background__2 {
            opacity: 0;

            img {
                object-position: left;
            }
        }
        .background__3 {
            background: radial-gradient(90.44% 90.44% at 57.26% 108.49%, #F5D62D 0%, #5E1B0B 42.69%, #000000 76.03%);
            opacity: 0;
            @include tablet {
                width: 200%;
                left: -60%;
            }
        }
        .background__4 {
            background: radial-gradient(89.25% 89.25% at 50.04% 0%, #1B162D 0%, #181426 39.64%, #110E1A 64.71%, #000000 100%);
            opacity: 0;
        }
    }


}

// scroll animation
.screen-scroll__slides {
    &.-active-id-game {
        .game {
            .content__slides {
                transform: translate3d(0, 0, 0);
            }
        }
    }
    &.-active-id-game2 {
        .game {
            .content__slides {
                transform: translate3d(0, -100vh, 0);
            }
        }
    }
    &.-active-id-game3 {
        .game {
            .content__slides {
                transform: translate3d(0, -200vh, 0);
            }
        }
    }
    &.-active-id-game4, .-scrolled {
        .game {
            .content__slides {
                transform: translate3d(0, -300vh, 0);
            }
        }
    }
}

// animation
.slide {
    .game {
        .background__color {
            opacity: 0;
            transition: .75s opacity;
        }
    }

    &.-active {
        .game {
            .background__color {
                opacity: 1;
                // transition: 1s opacity .5s;
            }
        }
    }
}

$toAnimte: (
    '.game .wrapper__text h3',
    '.game .wrapper__image img',
);

$i: 0;
@each $class in $toAnimte {
    
    #{$class} {
        transition: .75s transform #{$i / 10}s ease, .75s opacity !important;
        transform: translateY(rem(150));
        display: inline-block;
        opacity: 0;
    }

    $i: $i + 1;
}

$backgrounds: (
    clouds: '.background__clouds',
    blur: '.background__blur',
    2: '.background__2',
    3: '.background__3',
    4: '.background__4'
);
@function bg($name) {
    @return map-get($backgrounds, $name);
}


.game {
    #{bg(clouds)}, #{bg(2)}, #{bg(3)}, #{bg(4)}, #{bg(blur)} {
        transition: opacity .75s ease, transform .75s ease;
    }
}

.screen-scroll__slides {
    &.-active-id-game {
        .game .slide_1 {
            .wrapper__text h3,
            .wrapper__image img {
                transform: none;
                opacity: 1;
            }
        }
        .game {
            #{bg(clouds)} {
                transform: scale(1.5);
            }
        }

    }
    &.-active-id-game2 {
        .game .slide_2 {
            .wrapper__text h3,
            .wrapper__image img {
                transform: none;
                opacity: 1;
            }
        }
        .game {
            #{bg(blur)} {
                opacity: 0;
            }
            #{bg(2)} {
                opacity: 1;
            }
        }
    }
    &.-active-id-game3 {
        .game .slide_3 {
            .wrapper__text h3,
            .wrapper__image img {
                transform: none;
                opacity: 1;
            }
        }
        .game {
            #{bg(blur)} {
                opacity: 0;
            }
            #{bg(clouds)} {
                transform: scale(3);
                opacity: 0;
            }
            #{bg(3)} {
                opacity: 1;
            }
        }
    }
    &.-active-id-game4, .-scrolled {
        .game .slide_4 {
            .wrapper__text h3,
            .wrapper__image img {
                transform: none;
                opacity: 1;
            }
        }
        .game {
            #{bg(blur)} {
                opacity: 0;
            }
            #{bg(clouds)} {
                transform: scale(3);
                opacity: 0;
            }
            #{bg(4)} {
                opacity: 1;
            }
        }
    }
    
}