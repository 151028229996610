@use "@/assets/styles/index.scss" as *;

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden !important;

  &__background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0; left: 0;
    z-index: -1;
    @include coverImage;

    &::after {
      content: '';
      position: absolute;
      top: 0; left: 0;
      width: 100%;
      height: 100%;
      background: color-background(50);
      opacity: 0.5;
    }

  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      @include font-scraft-regular;
      font-size: font(24);
      line-height: 124%;
      color: color-background(900);
    }

    h1 {
      margin-top: rem(11);
      .hidden {
        display: none;
      }
      .icon {
        width: rem(420);
        height: auto;
        color: color-constants-white(100)
      }
    }

    h3 {
      @include font-scraft-regular;
      font-size: font(24);
      line-height: 132%;
      color: color-background(900);
      margin-top: rem(14);
      text-align: center;
      max-width: rem(450);
    }

    .content__buttons {
      display: flex;
      align-items: center;
      margin-top: rem(32);

      > :not(:first-child) {
        margin-left: rem(15);
      }
    }
  }
}

// animation
$toAnimate: (
  '.main__content > :nth-child(1)',
  '.main__content > :nth-child(2)',
  '.main__content > :nth-child(3)',
  '.main__content > :nth-child(4)'
);

.slide {
  $i: 0;
  @each $class in $toAnimate {

    #{$class} {
      transform: translate3d(0, rem(-100), 0) scale(1.2);
      opacity: 0;
      transition: 0s opacity .5s, 0s transform .5s, 0s filter .5s;
    }

    &.-active {
      #{$class} {
        transform: none;
        opacity: 1;
        transition: 1s opacity #{$i / 5}s, 1s transform #{$i / 5}s, 1s filter #{$i / 5}s;
      }
    }
  
    $i: $i + 1;
  }


  .main__background {
    transform: scale(1.25);
    transition: .5s transform ease;
  }
  &.-active {
    .main__background {
      transition: 4s transform ease;
      transform: none;
    }
  }
}
