@use '@/assets/styles' as *;

.mint-news {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    width: rem(500);
    overflow: hidden;
    color: color-primary(600);
    z-index: 1;

    // &::before, &::after {
    //     content: '';
    //     position: absolute;
    //     width: rem(50);
    //     height: 100%;
    //     top: 0;
    //     z-index: 1;
    // }

    // &::before {
    //     left: 0;
    //     background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 95%);
    // }

    // &::after {
    //     right: 0;
    //     background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 95%);
    // }

    >* {
        text-align: center;
        min-width: 100%;
        width: 100%;
        font-size: font(24);
        line-height: 116%;
        @include font-scraft-regular;
        letter-spacing: -0.03em;
    }
}