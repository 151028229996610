@use 'sass:math';
@use '@/assets/styles' as *;

.mint__popupWrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(0, 0, 0, 0.629);

    .mint__popupTransaction {
        position: fixed;

        left: rem(20);
        bottom: rem(20);

        width: rem(200);
        height: rem(136);

        border: rem(1) solid rgba(34, 34, 47, 1);
        background-color: rgba(26, 26, 35, 1);

        border-radius: rem(20);

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        gap: rem(12);

        @include tablet {
            display: none;
        }

        .mint__popupTransactionTitle {
            @include font-scraft-regular;
            font-size: rem(18);
            line-height: rem(22);
            margin-bottom: rm(20);
            width: 100%;
            text-align: center
        }

        .mint__popupTransactionItem {
            padding-left: rem(12);
            @include font-scraft-regular;
            font-size: rem(18);
            line-height: rem(22);
        }

    }

    .mint__popup {
        width: rem(400);
        // height: rem(336);

        border: rem(1) solid rgba(34, 34, 47, 1);
        background-color: rgba(26, 26, 35, 1);

        border-radius: rem(20);

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;

        padding: rem(16);
        gap: rem(24);
        padding-top: rem(42);

        .mint__popupCloseButton {
            position: absolute;
            top: rem(6);
            right: rem(16);

            transition: opacity 300ms ease-in-out;

            &:hover {
                opacity: 0.6;
            }
        }

        span {
            margin-bottom: rem(32);
        }

        p {
            @include font-scraft-regular;
            text-align: center;
            font-size: rem(20);
            line-height: rem(27);
            color: white;
            // opacity: 1;
        }

        .mint__popupConnectors {
            display: flex;
            flex-direction: column;
            gap: rem(16);
            width: 100%;

            .mint__popupConnectorsItem {
                display: flex;
                align-items: center;

                width: 100%;
                border: rem(1) solid rgba(34, 34, 47, 1);
                border-radius: rem(20);
                gap: rem(20);
                padding: rem(16);

                &:hover {
                    background-color: rgba(54, 54, 68, 0.529);
                }

                @include font-scraft-regular;
                text-align: center;
                font-size: rem(20);
                line-height: rem(27);
                color: white;

                .mint__popupConnectorsItemWallet {
                    width: rem(35);
                    height: rem(35);

                    img {
                        width: rem(35);
                        height: rem(35);
                    }
                }
            }
        }

        .mint__popupButton {
            padding: rem(13) rem(24);
            display: flex;
            background-color: white;
            color: black;

            text-transform: uppercase;
            @include font-scraft-regular;
            font-size: rem(18);
            line-height: rem(22);

            border-radius: rem(38);

            transition: background-color 200ms ease-in-out;

            &:hover {
                background-color: rgb(144, 144, 144);
            }

        }

        .mint__popupImg {
            width: rem(80);
            height: rem(80);


            img {
                width: rem(80);
                height: rem(80);
                // object-fit: cover;

            }
        }
    }
}