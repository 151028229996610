@use '@/assets/styles' as *;

.tokenomics {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 100vh !important;

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: rem(1520);
        height: 100%;
        overflow: hidden;
        @include laptop-lg {
            max-width: rem(1220);
        }
        @include tablet {
            max-width: rem(920);
        }
        @include mobile {
            max-width: rem(500);
        }

        h2 {
            font-size: font(43);
            color: color-constants-white(100);
            line-height: 116%;
            font-weight: 400;
            span {
                transition: .5s transform ease;
            }
            @include tablet {
                display: none;
            }
        }

        .content__table {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            margin-top: rem(16);
            overflow: hidden;
            width: 100%;
            background: rgba(143, 104, 223, 0.12);
            border-radius: rem(30);  
            padding: rem(16) rem(32);  
            @include mobile {
                display: none;
            }

            h3 {
                font-size: font(28);
                line-height: 132%;
                color: color-constants-white(100);
            }

            .table__list {
                display: grid;
                grid-template: 1fr / repeat(auto-fit, minmax(#{rem(110)}, 1fr));
                column-gap: rem(300);
                margin-top: rem(27);
                margin-left: rem(152);
                width: 100%;
                flex: 1;
                @include tablet {
                    column-gap: rem(16);
                    margin-left: rem(64);
                }

                .list__column {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    padding-left: rem(16);

                    &::before, &::after {
                        content: '';
                        position: absolute;
                        top: 0; left: 0;
                    }
                    &::before {
                        width: 1px;
                        height: 100vh;
                        background: color-constants-white(100);
                        opacity: 0.2;
                        transition: opacity .5s;
                    }
                    &::after {
                        width: 8px;
                        height: 8px;
                        min-width: 8px;
                        min-height: 8px;
                        border-radius: 100%;
                        background: color-constants-white(100);
                        transform: translateX(-50%);
                    }

                    h5 {
                        font-size: font(16);
                        line-height: 96%;
                        color: color-constants-white(64);
                        font-weight: 400;
                        white-space: nowrap;
                    }

                    p {
                        font-size: font(24);
                        line-height: 116%;
                        color: color-constants-white(100);
                        font-weight: 400;
                        margin-top: rem(4);
                        @include mobile {
                            font-size: font(24);
                        }
                    }
                }
            }
        }   

        .content__stats {
            width: 100%;
            display: flex;
            align-items: center;
            color: color-constants-white(100);
            margin-top: rem(32);
            padding: rem(32);
            padding-right: 0;
            background: rgba(143, 104, 223, 0.12);
            border-radius: rem(30);
            overflow: hidden;

            @include mobile {
                align-items: flex-start;
                flex-direction: column;
            }

            .stats__total {
                @include mobile {
                    display: flex;
                    align-items: center;
                }

                .donut {
                    position: relative;
                    width: rem(240);
                    height: rem(240);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    z-index: 1;
                    margin-top: rem(38);
                    white-space: nowrap;

                    @include mobile {
                        width: rem(160);
                        height: rem(160);
                        margin-left: rem(100);
                        margin-top: rem(0);
                    }

                    &__total {
                        position: absolute;
                        top: 0; left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: -1;

                        svg {
                            width: 100%;
                            height: 100%;
                            @include mobile {
                                transform: scale(1.25);
                            }
                        }
                    }

                    .initial-price, .total-supply {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }

                    h5 {
                        text-align: center;
                        font-size: font(24);
                        line-height: 116%;
                        color: color-constants-white(100);
                        margin-top: rem(4);
                    }

                    h6 {
                        font-size: font(16);
                        margin-bottom: rem(4);
                    }

                    .border {
                        width: rem(16);
                        height: 2px;
                        background: color-constants-white(64);
                        margin: rem(11) 0;

                    }

                    p {
                        text-align: center;
                        font-size: font(16);
                        line-height: 96%;
                        color: color-constants-white(64);
                    }
                }

                h3 {
                    font-size: font(28);
                    line-height: 108%;
                    color: color-constants-white(100);
                    text-align: center;
                    margin-top: rem(58);
                    @include laptop-lg {
                        margin-top: rem(24);
                    }
                    @include mobile {
                        margin-top: rem(24);
                    }
                }
            }

            .stats__list {
                width: 100%;
                display: grid;
                grid-template: 1fr / repeat(5, 1fr);
                row-gap: rem(32);
                @include tablet {
                    margin-left: rem(24);
                }
                @include mobile {
                    grid-template: 1fr / repeat(auto-fit, minmax(#{rem(110)}, 1fr));
                    row-gap: rem(32);
                    column-gap: rem(9);
                    margin-top: rem(24);
                    margin-left: 0;
                }

                .list__item {
                    max-width: rem(95);
                    justify-self: center;
                    @include tablet {
                        // overflow: hidden;
                        justify-self: flex-start;
                    }

                    .item__donut {
                        position: relative;
                        width: rem(115);
                        height: rem(75);
                        display: flex;
                        align-items: flex-end;
                        justify-content: flex-start;
                        z-index: 1;
                        @include mobile {
                            width: rem(57);
                            height: rem(37);
                            padding-top: rem(64);
                        }

                        svg {
                            position: absolute;
                            top: 0; left: 0;
                            width: 100%;
                            height: 100%;
                            z-index: -1;
                        }

                        p {
                            font-size: rem(22);
                            color: color-constants-white(100);
                            line-height: 108%;
                            font-weight: 400;
                        }
                    }

                    h5 {
                        margin-top: rem(16);
                        font-size: font(16);
                        line-height: 96%;
                        white-space: nowrap;
                        @include tablet {
                            max-width: 100%;
                        }
                    }

                    .text {
                        font-size: font(12);
                        color: color-constants-white(64);
                        line-height: 108%;
                        font-weight: 300;
                        margin-top: rem(10);
                    }

                    .subtext {
                        color: color-constants-white(64);
                        font-size: rem(10);
                        line-height: 96%;
                        font-weight: 300;
                        margin-top: rem(10);
                        @include mobile {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                        }

                        span {
                            background: color-validation(error);
                            padding: rem(4) rem(8) rem(4) rem(6);
                            border-radius: rem(40);
                            margin-right: rem(4);
                            line-height: 100%;
                            color: color-constants-white(100);
                            @include mobile {
                                margin-right: 0;
                                margin-bottom: rem(4);
                            }
                        }
                    }

                }
            }
        }

        .token {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .icon {
                width: rem(48);
                height: rem(48);
            }

            p {
                margin-left: rem(16);
                font-size: font(20);
                line-height: 115%;
                color: color-constants-white(100);
            }
        }

    }

    &__background {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
}

// animation
.tokenomics {
    @for $i from 1 through 15 {
        .content__table > :nth-child(#{$i}) {
            transition: .75s transform #{$i / 10}s ease, .75s opacity #{$i / 10}s;
            transform: translateY(rem(100));
            opacity: 0;
        }
        .stats__list > :nth-child(#{$i}) {
            transform: translateY(rem(100));
            opacity: 0;
            transition: 1s transform #{$i / 15}s ease, 1s opacity #{$i / 15}s;

            .offset {
                transition: 1s stroke-dashoffset #{$i / 15}s ease, 1s opacity #{$i / 15}s;
            }
        }
    }

    h2 {
        transition: .75s transform ease, .75s opacity;
        transform: translateY(rem(100));
        opacity: 0;
    }

    .content__table {
        transition: .75s transform .1s ease, .75s opacity .1s;
        transform: translateY(rem(100));
        opacity: 0;
    }
    .content__stats {
        transition: .75s transform .2s ease, .75s opacity .2s;
        transform: translateY(rem(100));
        opacity: 0;
    }
}

.screen-scroll__slides {
    &.-active-id-tokenomics {
        .tokenomics {
            h2,
            .content__table > *,
            .stats__list > *,
            .content__table, .content__stats {
                opacity: 1;
                transform: none;
            }
            
            .stats__list {
                .offset { opacity: 1; }
                > :nth-child(1) .offset { stroke-dashoffset: 50; }
                > :nth-child(2) .offset { stroke-dashoffset: 70; }
                > :nth-child(3) .offset { stroke-dashoffset: 80; }
                > :nth-child(4) .offset { stroke-dashoffset: 80; }
                > :nth-child(5) .offset { stroke-dashoffset: 90; }
                > :nth-child(6) .offset { stroke-dashoffset: 95; }
                > :nth-child(7) .offset { stroke-dashoffset: 10; }
                > :nth-child(8) .offset { stroke-dashoffset: 19; }
                > :nth-child(9) .offset { stroke-dashoffset: 50; }
                > :nth-child(10) .offset { stroke-dashoffset: 50; }
            }
        }
    }
}