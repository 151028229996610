@use "@/assets/styles/" as *;
@use "sass:math";

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: rem(35) rem(35) rem(0);
  z-index: 99999;

  &__logo {
    width: rem(152);
    height: auto;
    @include cursor-pointer;
    transition: opacity .5s ease;

    svg{
      color: color-constants-white(100);
    }
  }

  .header__titles{
    opacity: 1 !important;
  }
  @include tablet{
    .header__logo{
      opacity: 0 !important;
    
    }
    .header__titles{
      opacity: 0 !important;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;

    > * {
      @include tablet {
        // display: none !important;
      }
    }

    > :not(:first-child) {
      margin-left: rem(16);
    }

    .button-cross {
      display: none;
    }
  }

  .header__titles {
    position: relative;
    margin-right: auto;
    margin-left: rem(32);
    transition: opacity .5s ease, visibility .5s ease;
    @include tablet {
      margin-left: auto;
      margin-right: 0;
    }
    h2 {
      position: absolute;
      top: 0; left: 0;
      transform: translateY(calc(-50% - 4px));
      white-space: nowrap;
      color: color-constants-white(100);
      font-size: font(24);
      transition: .75s opacity ease, .75s visibility ease;
      opacity: 0;
      visibility: none;
      @include tablet {
        left: auto;
        right: 0;
      }
    }
  }
}

// animation
$toAnimate: (
  '.header__logo',
);

.header {
  $i: 0;
  @each $class in $toAnimate {
    #{$class} {
      transform: translateY(rem(-150));
      opacity: 0;
      transition: .5s transform #{math.div($i, 10)}s ease, .5s opacity #{math.div($i, 10)}s ease;
    }

    &.-active {
      #{$class} {
        transform: translateY(0);
        opacity: 1;
      }
    }

    &.-hide-logo {
      .header__logo {
        transform: translateY(rem(-150));
        opacity: 0;
      }
    }

    $i: $i + 1;
  }
}

.header.-mint-page {
  .header__titles, .button-mint {
    opacity: 0 !important;
    visibility: hidden !important;
    display: none;

  
  }
  .header__logo {
    transform: none !important;
    opacity: 1 !important;

    @include tablet{
      padding-right: rem(20);
    }
  }
  .button-cross {
    display: flex !important;
  }
}

.header.-myspace-page {
  .header__titles, .button-login {
    opacity: 0 !important;
    visibility: hidden !important;
    display: none;

  
  }
  .header__logo {
    transform: none !important;
    opacity: 1 !important;

    @include tablet{
      padding-right: rem(20);
    }
  }
  .button-cross {
    display: flex !important;
  }
}


// header titles
@include laptop-lg {
  .-show-features .title_features,
  .-show-team .title_team {
    opacity: 1 !important;
    visibility: visible !important;
  }

}
@include tablet {
  .-show-features .title_features, 
  .-show-game2 .title_game, 
  .-show-game3 .title_game, 
  .-show-game4 .title_game, 
  .-show-generation2 .title_generation,
  .-show-generation3 .title_generation,
  .-show-genesis .title_genesis,
  .-show-genesis2 .title_genesis2,
  .-show-park2 .title_park,
  .-show-park3 .title_park,
  .-show-tokenomics .title_tokenomics,
  .-show-team .title_team,
  .-show-faq .title_faq
  {
    opacity: 1 !important;
    visibility: visible !important;
  }
}


