@use 'sass:math';
@use '@/assets/styles' as *;

.mint {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: color-constants-white(100);
    height: 100vh;
    overflow: hidden;
    padding-bottom: rem(200);

    h1 {
        @include font-scraft-regular;
        font-size: rem(56);
        max-width: rem(700);
        line-height: 115%;
        letter-spacing: -0.04em;
        text-align: center;

        @include mobile {
            max-width: rem(400);
        }
    }

    .mint-news {
        margin-top: rem(20);
    }



    .mint__popupWrapper {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 99;
        top: 0;
        left: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: rgba(0, 0, 0, 0.629);

        .mint__popupTransaction{
            position: fixed;

            left: rem(20);
            bottom: rem(20);

            width: rem(200);
            height: rem(136);

            border: rem(1) solid rgba(34, 34, 47, 1);
            background-color: rgba(26, 26, 35, 1);

            border-radius: rem(20);

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            gap: rem(12);

            @include tablet{
                display: none;
            }

            .mint__popupTransactionTitle{
                @include font-scraft-regular;
                font-size: rem(18);
                line-height: rem(22);
                margin-bottom: rm(20);
                width: 100%;
                text-align: center
            }
            .mint__popupTransactionItem{
                padding-left: rem(12);
                @include font-scraft-regular;
                font-size: rem(18);
                line-height: rem(22);
            }

        }

        .mint__popup {
            width: rem(400);
            height: rem(336);

            border: rem(1) solid rgba(34, 34, 47, 1);
            background-color: rgba(26, 26, 35, 1);

            border-radius: rem(20);

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            gap: rem(32);

            span {
                margin-bottom: rem(32);
            }

            p {
                @include font-scraft-regular;
                text-align: center;
                font-size: rem(20);
                line-height: rem(27);
            }

            .mint__popupButton {
                padding: rem(13) rem(24);
                display: flex;
                background-color: white;
                color: black;

                text-transform: uppercase;
                @include font-scraft-regular;
                font-size: rem(18);
                line-height: rem(22);

                border-radius: rem(38);

                transition: background-color 200ms ease-in-out;

                &:hover {
                    background-color: rgb(144, 144, 144);
                }

            }

            .mint__popupImg {
                width: rem(80);
                height: rem(80);


                img {
                    width: rem(80);
                    height: rem(80);
                    // object-fit: cover;

                }
            }
        }
    }

    .mint__subinfo {

        transition: opacity 300ms ease-in-out;

        &:hover {
            @include cursor-pointer;
            opacity: 0.7;
        }
    }

    .button-container {
        margin-top: rem(47);
    }

    .MintBlock {
        margin-top: rem(50);
        display: flex;
        gap: rem(32);

        align-items: center;

        .MintBlockImg {
            width: rem(174);
            height: rem(174);

            img {
                width: rem(174);
                height: rem(174);
            }
        }

        .MintBlockInfo {
            width: rem(234);

            display: flex;
            flex-direction: column;
            gap: rem(12);

            .MintBlockInfo_button {
                @include cursor-pointer;
                height: rem(48);

                width: 100%;
                color: color-constants-white(100);
                background-color: color-secondary(400);
                padding-right: rem(20);
                border-color: color-secondary(400);


                display: flex;
                justify-content: center;
                align-items: center;
                gap: rem(8);
                padding: rem(12) rem(14);

                border-radius: rem(38);
                border-radius: rem(38);
                @include font-scraft-regular;

                font-size: font(18);
                font-weight: 400;
                line-height: 100%;

                &:hover {
                    background-color: rgb(16, 117, 84);


                }

            }



            .MintBlockInfo_item {
                display: flex;
                align-items: center;
                justify-content: space-between;

                p {
                    font-size: rem(18);
                    line-height: rem(22);
                    @include font-gcraft-semibold;
                }
            }

            .MintBlockInfo_Quantity {
                display: flex;
                align-items: center;
                gap: rem(9);
            }

            .MintBlockInfo_itemQuantity {
                @include cursor-pointer;
                width: rem(32);
                height: rem(32);

                border-radius: 50%;
                border: rem(1) solid rgba(255, 255, 255, 0.24);

                display: flex;
                align-items: center;
                justify-content: center;

                text-align: center;

                transition: all 300ms ease-in-out;

                font-size: rem(13);

                line-height: rem(16);

                @include font-scraft-bold;

                &:hover {

                    background-color: white;
                    color: black;
                    border-color: white;

                }


            }

            .activeitemQuantity {
                background-color: white;
                color: black;
                border-color: white;
            }
        }
    }

    &__subinfo {
        opacity: 0.4;
        font-size: rem(12);
        margin-top: rem(36);
    }

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        perspective: 1000px;
        z-index: -1;

        >* {
            position: absolute;
        }

        .space {
            width: 100%;
            height: 100%;
            @include coverImage;
        }
    }
}

.mint_successfullyConnectedWalletWrapper {

    position: absolute;
    bottom: rem(48);
    left: 0;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 1;
    transition: opacity 300ms ease-in-out;

    .mint_successfullyConnectedWallet {
        padding: rem(10);

        border-radius: rem(53);

        background-color: #27272ECC;

        display: flex;
        align-items: center;
        gap: rem(9);

        .mint_successfullyConnectedWallet_icon {
            width: rem(24);
            height: rem(24);

            svg {
                width: rem(24);
                height: rem(24);
            }
        }

        p {
            @include font-scraft-regular;
            font-size: rem(15);
            line-height: rem(15);
            font-weight: 500;
        }
    }
}

.mint_successfullyConnectedWalletWrapperHide {
    opacity: 0;
    pointer-events: none;
}

.blur {
    backdrop-filter: blur(rem(5));
    position: absolute;
    width: 100%;
    height: 100%;
    // border: 10px solid red;

    transition: backdrop-filter 300ms ease-in-out;
    pointer-events: none;
}

.blurHide{
    backdrop-filter: blur(rem(0));
}

.loader {
    color: #fff;
    font-size: 10px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    animation: mulShdSpin 1.3s infinite linear;
    transform: translateZ(0);
}

.mint__popupTransactionItemBlock{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: rem(20);
    animation: blink-animation 2s infinite;
}

@keyframes blink-animation {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

.mint__popupTransactionItemLoaderWrapper{
}

.loaderMini {
    // margin-top: 380%;
    display: block !important;
    color: #fff;
    font-size: rem(4);
    width: rem(5);
    height: rem(5);
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    animation: mulShdSpin 1.3s infinite linear;
    transform: translateZ(0);
    // border: 1px solid red;
}

@keyframes mulShdSpin {

    0%,
    100% {
        box-shadow: 0 -3em 0 0.2em,
            2em -2em 0 0em, 3em 0 0 -1em,
            2em 2em 0 -1em, 0 3em 0 -1em,
            -2em 2em 0 -1em, -3em 0 0 -1em,
            -2em -2em 0 0;
    }

    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em,
            3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em,
            -2em 2em 0 -1em, -3em 0 0 -1em,
            -2em -2em 0 -1em;
    }

    25% {
        box-shadow: 0 -3em 0 -0.5em,
            2em -2em 0 0, 3em 0 0 0.2em,
            2em 2em 0 0, 0 3em 0 -1em,
            -2em 2em 0 -1em, -3em 0 0 -1em,
            -2em -2em 0 -1em;
    }

    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
            3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em,
            -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
            3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em,
            -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
            3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0,
            -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }

    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em,
            3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
            -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }

    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em,
            3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
            -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}


.disabled{
    pointer-events: none;
    opacity: 0.6;
}