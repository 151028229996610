@use 'sass:math';
@use '@/assets/styles' as *;


.FAQDonut{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    margin-top: rem(10);
    white-space: nowrap;
    margin-right: rem(24);

    .donut__total{
        position: absolute;
        top: 0; left: 0;
        width: rem(160);
        height: rem(160);
        z-index: -1;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    .total-supply{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: rem(160);
        height: rem(160);

        h6 {
            font-size: font(14);
            margin-bottom: rem(4);
            color: color-constants-white(100);
        }

        p {
            text-align: center;
            font-size: font(12);
            line-height: 96%;
            color: color-constants-white(64);
        }
    }
}