@use '@/assets/styles' as *;

.faq {
    // position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    // height: 100% !important;

    &__content {
        display: flex;
        align-items: flex-start;
        height: 100%;
        padding-bottom: rem(100);
        @include mobile {
            padding-right: rem(30);
        }

        h2 {
            font-size: font(53);
            line-height: 96%;
            color: color-constants-white(100);
            margin-top: rem(150);
            @include tablet {
                display: none;
            }
        }

        h4 {
            font-size: rem(34);
            line-height: 116%;
            color: color-constants-white(100);
            margin-top: rem(4);
            display: none;
            @include tablet {
                font-size: font(24);
            }
            @include mobile {
                font-size: font(16);
                margin-top: rem(20);
            }
        }

        .content__list {
            position: relative;
            // max-height: 100%;
            // overflow-y: scroll;
            margin-left: rem(124);
            padding-top: rem(150);
            height: 100%;
            // @include noneScrollbar;

            @include tablet {
                margin-left: rem(64);
            }
            @include mobile {
                margin-left: rem(32);
            }


            .list__item {
                display: flex;
                align-items: flex-start;
                height: 100%;
            }
        }

        .questions {
            position: relative;
            padding-left: rem(60);
            margin-left: rem(64);
            min-height: 100%;
            @include tablet {
                margin-left: rem(32);
                padding-left: rem(32);
            }
            @include mobile {
                margin-left: rem(16);
            }

            &::before, &::after {
                content: '';
                position: absolute;
                top: rem(24); left: 0;
                background-color: color-constants-white(100);
            }
            &::before {
                width: 1px;
                height: calc(100% + 100vh);
                opacity: 0.2;
            }
            &::after {
                width: 8px;
                height: 8px;
                transform: translateX(-50%);
                border-radius: 100%;
            }

        }

        .accordion {
            max-width: rem(650);
            @include mobile {
                max-width: rem(400);
            }
        }
    }

    &__background {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
}