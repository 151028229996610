@use './colors';

$scrollbar-tumb: #000;
$scrollbar-track: #F0F0;
$scrollbar-hide: #000;

@mixin noneScrollbar() {
    -ms-overflow-style: none;
    scrollbar-width: none; 
    &::-webkit-scrollbar {
        display: none;
    }
}

@mixin hideScrollbar() {
    scrollbar-color: $scrollbar-hide $scrollbar-hide;
    ::-webkit-scrollbar-track {
      background-color: $scrollbar-hide
    }
  
    ::-webkit-scrollbar-thumb {
      background-color: $scrollbar-hide
    }
}

@mixin setScrollbar() {
    scrollbar-color: $scrollbar-tumb $scrollbar-track;
    scrollbar-width: thin;
  
    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
  
    ::-webkit-scrollbar-track {
        background-color: $scrollbar-track;
    }
  
    ::-webkit-scrollbar-thumb {
      background-color: $scrollbar-tumb;
    }
}


@mixin coverImage {
  > * {
      width: 100%;
      height: 100%;
  }

  img {
      width: 100%;
      height: 100%;
      object-fit: cover;
  }
}

@mixin containImage {
  > * {
      width: 100%;
      height: 100%;
  }

  img {
      width: 100%;
      height: 100%;
      object-fit: contain;
  }
}
