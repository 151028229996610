@use 'sass:math';
@use '@/assets/styles' as *;

.FAQ{
    width: rem(1300);
    position: relative;
    padding: rem(66) rem(240) rem(90) rem(240);
    box-shadow: rem(0) rem(16) rem(56) rgba(0, 0, 0, 0.25);
    border-radius: rem(32);
    border: rem(1) solid rgba(255, 255, 255, 0.08);
    background: rgba(84, 73, 101, 0.1);
    margin-bottom: rem(72);
    
    @include tablet{
        width: rem(853);
        padding: rem(66) rem(200) rem(90) rem(200);
    }

    @include mobile{
        width: rem(506);
        padding: rem(66) rem(28) rem(90) rem(28);
    }

    &__shade{
        position: absolute;
        background: #57C3DB;
        top: 0;
        left: 0;
        transform: translate(rem(-307), rem(0));
        opacity: 0.2;
        filter: blur(100px);
        width: rem(1134);
        height: rem(75);
    }

    &__list{

        &__item{
            margin-left: rem(56);
        }

        .questions{
            &::before, &::after {
                content: '';
                position: absolute;
                top: rem(158); left: rem(243.6);
                background-color: color-constants-white(100);

                @include tablet{
                    top: rem(135); left: rem(203.6);
                }

                @include mobile{
                    top: rem(158); left: rem(31);
                }
            }
            &::before {
                width: 1px;
                height: 74%;
                opacity: 0.2;
            }
            &::after {
                width: 8px;
                height: 8px;
                transform: translateX(-50%);
                border-radius: 100%;
            }
        }
    }

    &__title{
        font-size: rem(35);
        letter-spacing: -0.03em;
        line-height: 116%;
        margin-bottom: rem(38);

        //Уменьшать ли шрифт при адаптиве?
    }

    &__UFO{
        user-select: none;
        position: absolute;
        width: rem(246);
        height: rem(326);
        left: rem(-153);
        top: rem(-50);
        z-index: 1;

        @include mobile{
            width: rem(142);
            height: rem(188);
        }
    }

    &__red-baloon{
        user-select: none;
        position: absolute;
        width: rem(183);
        height: rem(329);
        right: rem(-95);
        top: 40%;
        // transform: translate(rem(95), 40%);

        @include tablet{
            width: rem(115);
            height: rem(263);
            transform: translate(rem(80), 60%);
        }
    }

    &__shaman-image{
        user-select: none;
        position: absolute;
        width: rem(567);
        height: rem(717);
        left: rem(-121);
        bottom: rem(-580);
        // transform: translate(rem(-121), rem(580));
        pointer-events: none;

        @include tablet{
            width: rem(453);
            height: rem(536);
            transform: translate(rem(-209), rem(431));
        }

        @include mobile{
            // width: rem(395);
            // height: rem(501);
            // transform: translate(rem(-335), rem(386));
            display: none;
        }
    }

    &__house-image{
        user-select: none;
        position: absolute;
        width: rem(480);
        height: rem(627);
        right: 0;
        bottom: 0;
        transform: translate(rem(121), rem(556));

        @include tablet{
            width: rem(394);
            height: rem(537);
            transform: translate(rem(156), rem(495));
        }

        @include mobile{
            width: rem(323);
            height: rem(441);
            transform: translate(rem(270), rem(428));
        }
    }
}