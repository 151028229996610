@use 'sass:math';
@use '@/assets/styles' as *;


.myspace__footer{
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__logo{
        margin-bottom: rem(12);
        width: rem(101);
        height: rem(24);

        svg{
            color: rgba(255, 255, 255, 0.4);
        }

        @include tablet{
            width: rem(84);
            height: rem(20);
        }
    }

    &__media{
        display: flex;
        gap: rem(24);

        a {
            width: rem(24);
            height: rem(24);
            &:hover {
                .icon {
                    opacity: .7;
                    transform: translateY(-2px);
                }
            }

            @include tablet{
                width: rem(20);
                height: rem(20);
            }

            .icon {
                width: 100%;
                height: 100%;
                transition: transform .3s ease, opacity .3s;

                svg{
                    color: rgba(255, 255, 255, 0.4);
                }
            }
        }
    }

    p{
        margin-top: rem(12);
        @include font-scraft-bold;
        font-size: rem(12);
        color: #FFFFFF66;

        @include tablet{
            width: rem(317);
            text-align: center;
        }
    }
}