@use '@/assets/styles' as *;

.features {
    position: relative;
    z-index: 1;
    background: color-background(50);
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
        padding-left: rem(32) !important;
        padding-right: rem(32) !important;
    }

    .border-bottom {
        z-index: 2;
        transform: translateZ(1px);
    }

    &__content {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        h2 {
            font-size: font(53);
            color: color-constants-white(100);
            line-height: 95%;
            @include laptop-lg {
                display: none;
            }
        }

        .content__list {
            display: grid;
            grid-template: 1fr / repeat(3, 1fr);
            row-gap: rem(55);
            column-gap: rem(140);
            margin-top: rem(32);

            @include laptop-lg {
                row-gap: rem(24);
                column-gap: rem(64);
            }

            @include tablet {
                grid-template: 1fr / repeat(2, 1fr);
            }

            @include mobile {
                grid-template: 1fr / repeat(2, 1fr);
                row-gap: rem(16);
                column-gap: rem(16);
            }
        }

        .list__item {
            max-width: rem(315);
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .item__preview {
                width: auto;
                height: rem(100);
                @include containImage;
            }

            h4 {
                font-size: font(28);
                color: color-text(900);
                line-height: 116%;
                margin-top: rem(16);
            }

            p {
                font-size: font(16);
                line-height: 132%;
                color: color-text(700);
                opacity: 0.8;
                margin-top: rem(12);
            }
        }
    }

    &__background {
        z-index: -1;
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;

        .background {

            &__forest, &__clouds {
                position: absolute;
                top: 0; left: 0;
                width: 100%;
                height: 100%;
                @include coverImage;
            }

            &__forest {
                position: relative;
                z-index: 0;
                img {
                    object-position: 50% 100%;
                }

            }

            &__clouds {
                img {
                    object-position: 0 50%;
                }
                z-index: 2;
            }

            &__hut {
                position: absolute;
                width: rem(720);
                height: rem(942);
                bottom: 0; right: 0;
                transform: translate(-10%, 23%);
                z-index: 1;
                @include tablet {
                    width: rem(520);
                    height: rem(742);
                    transform: translate(10%, 23%);
                }
                
                .hut__wrapper {
                    width: 100%;
                    height: 100%;
                    @include containImage;
                }
            }

        }
    }
}


// animation
.features {
    .background__forest {
        // transition: .5s opacity, .5s transform ease;
        // transform: scale(1.2);
    }
    .background__clouds {
        transition: .75s opacity, .75s transform ease;
        transform: translateX(rem(-100));
        opacity: 0.5;
    }
    .hut__wrapper {
        transition: .75s opacity, .75s transform ease;
        opacity: 0.5;
        transform: scale(1.1) translateY(rem(100));
    }
}

.slide {
    &.-active {
        .features {
            .hut__wrapper, .background__clouds {
                opacity: 1;
                transform: none;
                transition: 3s opacity, 3s transform ease-out;
            }
        }
    }
}

$toAnimate: (
    '.features__content h2',
    '.features .content__list > :nth-child(1)',
    '.features .content__list > :nth-child(2)',
    '.features .content__list > :nth-child(3)',
    '.features .content__list > :nth-child(4)',
    '.features .content__list > :nth-child(5)',
    '.features .content__list > :nth-child(6)',
);

.slide {
    $i: 0;

    @each $class in $toAnimate {

        #{$class} {
            transform: translate3d(0, rem(50), 0);
            transition: .75s transform;
        }

        &.-active {
            #{$class} {
                transform: none;
                transition: 1s transform #{$i / 10}s ease;
            }
        }

        $i: $i + 1;
    }

}